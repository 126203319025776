import IMask, { InputMaskElement } from "imask";

export const phoneMask = (element: InputMaskElement) => {
  if (element) {
    return IMask(element, {
      mask: "+{1} 000 000 0000",
      lazy: true,
      discardInvalid: false,
      overwrite: false,
    });
  }
};

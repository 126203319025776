import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import _ from "lodash";

import Box from "components/Box";
import Chip from "components/Chip";
import Link from "components/Link";
import Stack from "components/Stack";
import Text from "components/Text";
import Divider from "components/Divider";
import Container from "components/Container";
import BasicButton from "components/BasicButton";
import FullPageDialog from "components/FullPageDialog";
import FileUploadBox from "components/FileUploadBox";
import { CircularProgress } from "components/Loading";
import useMediaQuery from "components/useMediaQuery";

import { uploadAndParseDocument } from "entities/Documents/sdk";
import { useMyFamily } from "entities/Family/sdk";

import { URLS } from "config/urls";
import { colors } from "theme";
import { CheckIcon, AttentionIcon } from "icons";
import { reverse } from "utils/urls";

const MissingInformationVerificationPage = () => {
  const { parentId: parentIdParam, voucherId: voucherIdParam } = useParams<{
    parentId: string;
    voucherId: string;
  }>();
  const parentId = parseInt(String(parentIdParam));
  const voucherId = parseInt(String(voucherIdParam));
  const { t } = useTranslation();
  const [isUploading, setIsUploading] = useState(false);
  const matches = useMediaQuery("(min-width:600px)");

  const { data: family, mutate: reloadFamily } = useMyFamily();
  const parent = family?.family_members.find(
    (parent) => parent.id === parentId
  );

  const parentDocuments =
    family?.documents.filter(
      (document) =>
        document.parent?.id === parentId &&
        document.vouchers?.map((v) => v.id).includes(voucherId)
    ) || [];

  const uploadedParentDocuments =
    parentDocuments?.filter((document) =>
      document.parsing_types.includes("PARENT_IDENTITY")
    ) || [];

  const allUploadedParentDocumentsAreParsed = uploadedParentDocuments.every(
    (document) => document.is_ready
  );

  const identityConfirmedDocuments = parentDocuments.filter(
    (document) => document.id_verification
  );

  const identityConfirmed = identityConfirmedDocuments.length !== 0;

  useEffect(() => {
    if (!allUploadedParentDocumentsAreParsed) {
      const interval = setInterval(
        () =>
          reloadFamily().then(() => {
            if (allUploadedParentDocumentsAreParsed) {
              clearInterval(interval);
              reloadFamily();
            }
          }),
        2000
      );
      return () => clearInterval(interval);
    }
  }, [allUploadedParentDocumentsAreParsed, reloadFamily]);

  if (!parent || !voucherIdParam || !family) {
    return null;
  }

  const handleFileSelect = (file: any) => {
    setIsUploading(true);
    uploadAndParseDocument({
      file,
      parentId,
      parsingTypes: ["PARENT_IDENTITY"],
      addToAllVouchers: true,
    }).then(() => {
      setIsUploading(false);
      reloadFamily();
    });
  };

  /* <Routing to previous and next parent> */
  const parentsOrdered = _.orderBy(family?.family_members, "id");
  const parentsVerificationsLeft = _.drop(
    _.dropWhile(parentsOrdered, (parent) => parent.id !== parentId),
    1
  );
  const nextParent = _.first(parentsVerificationsLeft);
  const parentsVerificationsDone = _.takeWhile(
    parentsOrdered,
    (parent) => parent.id !== parentId
  );
  const previousParent = _.last(parentsVerificationsDone);
  /* </Routing to previous and next parent> */

  return (
    <FullPageDialog
      title={t("verifyInformation")}
      progressBarPercentage={40}
      backUrl={
        previousParent
          ? reverse(URLS.MISSING_INFORMATION_VERIFICATION_FOR_PARENT, {
              parentId: previousParent.id,
              voucherId: voucherIdParam,
            })
          : reverse(URLS.MISSING_INFORMATION_CHILDREN, {
              voucherId: voucherIdParam,
            })
      }
      closeUrl={URLS.HOME}
    >
      <Stack
        sx={{
          justifyContent: "space-between",
          flex: 1,
        }}
      >
        <Container
          maxWidth="sm"
          sx={{
            margin: "30px auto",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            flex: 1,
          }}
        >
          <Stack spacing={2}>
            <Text variant="titleMedium">{t("verifyInfo")}</Text>
            <Text variant="titleMedium" sx={{ color: "#675B57" }}>
              {t("proofOfID")} {parent.first_name} {parent.last_name}
            </Text>
            <Text variant="titleMedium" sx={{ fontWeight: 600 }}>
              {t("uploadPhotoID")}
            </Text>

            <Text variant="titleMedium">{t("commonIDs")}</Text>

            <Text variant="titleSmall" sx={{ color: "#B30838" }}>
              {t("noPhotoID")}
            </Text>

            <Stack spacing={1} direction="row" sx={{ alignItems: "center" }}>
              <Divider sx={{ flex: 1 }} />
              <Text variant="titleMedium" sx={{ fontWeight: 600 }}>
                {t("uploadRequiredDocuments")}
              </Text>
              <Divider sx={{ flex: 1 }} />
            </Stack>
            {_.uniqBy(
              [...uploadedParentDocuments, ...identityConfirmedDocuments],
              "id"
            ).map((document) => (
              <Box
                key={document.id}
                sx={{
                  padding: (theme) => theme.spacing(2, 0),
                  borderBottom: "1px solid #CFC8C3",
                }}
              >
                {document.file.original_file_name}
              </Box>
            ))}
            {isUploading && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress
                  thickness={8}
                  size={48}
                  sx={{ color: "#DA2C52" }}
                />
              </div>
            )}

            {!allUploadedParentDocumentsAreParsed && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <CircularProgress
                  thickness={8}
                  size={48}
                  sx={{ color: "#DA2C52" }}
                />
                <Text variant="labelLarge" sx={{ marginTop: "16px" }}>
                  {t("verifyingDocuments")}
                </Text>
              </div>
            )}

            {identityConfirmed && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Chip
                  icon={<CheckIcon />}
                  label={
                    <Text sx={{ fontWeight: "bold" }}>
                      {t("identityVerified")}
                    </Text>
                  }
                  sx={{ backgroundColor: colors.secondaryContainer }}
                />
              </div>
            )}
            {!identityConfirmed &&
              allUploadedParentDocumentsAreParsed &&
              parentDocuments.length > 0 && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <AttentionIcon />
                  </div>
                  <Text color="error">{t("couldNotVerifyIdentity")}</Text>
                </div>
              )}

            <FileUploadBox
              file={null}
              setFile={handleFileSelect}
              disabled={!allUploadedParentDocumentsAreParsed || isUploading}
            />
          </Stack>
        </Container>
        <Stack
          sx={{
            borderTop: "1px solid " + colors.outline,
            padding: (theme) => theme.spacing(4, 1),
            alignItems: "center",
          }}
        >
          <Link
            to={
              nextParent
                ? reverse(URLS.MISSING_INFORMATION_VERIFICATION_FOR_PARENT, {
                    parentId: nextParent.id,
                    voucherId: voucherIdParam,
                  })
                : reverse(URLS.MISSING_INFORMATION_PLACEMENT_LIST, {
                    voucherId: voucherIdParam,
                  })
            }
            style={{ width: matches ? "auto" : "100%" }}
          >
            <BasicButton label={t("next")} />
          </Link>
        </Stack>
      </Stack>
    </FullPageDialog>
  );
};

export default MissingInformationVerificationPage;

export const downloadFileWithCustomName = (
  fileUrl: string,
  customFileName: string
) => {
  return fetch(fileUrl)
    .then((response) => {
      if (!response.ok) {
        throw new Error("File download failed");
      }
      return response.blob();
    })
    .then((blob) => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = customFileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href);
    });
};

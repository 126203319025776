import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import _ from "lodash";

import Box from "components/Box";
import Chip from "components/Chip";
import Link from "components/Link";
import Stack from "components/Stack";
import Text from "components/Text";
import Divider from "components/Divider";
import Container from "components/Container";
import BasicButton from "components/BasicButton";
import FullPageDialog from "components/FullPageDialog";
import FileUploadBox from "components/FileUploadBox";
import { CircularProgress } from "components/Loading";
import useMediaQuery from "components/useMediaQuery";

import { providerConfirmationUploadDocument } from "entities/Documents/sdk";
import { useMyFamily } from "entities/Family/sdk";

import { URLS } from "config/urls";
import { colors } from "theme";
import { CheckIcon } from "icons";
import { reverse } from "utils/urls";

const ProviderConfirmationPage = () => {
  const { voucherId: vouchersIdParam, placementId: placementsIdParam } =
    useParams<{
      voucherId: string;
      placementId: string;
    }>();
  const { t } = useTranslation();
  const [isUploading, setIsUploading] = useState(false);
  const matches = useMediaQuery("(min-width:600px)");

  const { data: family, mutate: reloadFamily } = useMyFamily();
  const voucherId = vouchersIdParam ? parseInt(vouchersIdParam) : undefined;
  const placementId = placementsIdParam
    ? parseInt(placementsIdParam)
    : undefined;

  const placementDocuments =
    family?.documents.filter(
      (document) => document.placement?.id === placementId
    ) || [];

  const providerName = family?.vouchers
    ?.flatMap((voucher) => voucher.placements || [])
    .find((placement) => placement.id === placementId)?.provider_name;

  const providerConfirmationDocuments = placementDocuments.filter(
    (document) => document.provider_confirmation
  );
  const providerConfirmed = providerConfirmationDocuments.length !== 0;

  if (!family || !placementId || !voucherId) {
    return null;
  }

  let nextPlacementId: number | undefined = undefined;

  const allPlacements =
    family?.vouchers
      .find((voucher) => voucher.id === voucherId)
      ?.placements.map((placement) => placement.id) || [];

  const currentIndex = allPlacements.indexOf(placementId);

  if (currentIndex !== -1 && currentIndex + 1 < allPlacements.length) {
    nextPlacementId = allPlacements[currentIndex + 1];
  }

  const handleFileSelect = (file: any) => {
    setIsUploading(true);
    providerConfirmationUploadDocument({
      file,
      voucher: voucherId,
      familyId: family.id,
      placement: placementId,
      providerConfirmation: true,
    }).then(() => {
      setIsUploading(false);
      reloadFamily();
    });
  };

  return (
    <FullPageDialog
      title={t("providerConfirmation")}
      progressBarPercentage={70}
      backUrl={reverse(URLS.MISSING_INFORMATION_PLACEMENT_LIST, {
        voucherId,
      })}
      closeUrl={URLS.HOME}
    >
      <Stack
        sx={{
          justifyContent: "space-between",
          flex: 1,
        }}
      >
        <Container
          maxWidth="sm"
          sx={{
            margin: "30px auto",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            flex: 1,
          }}
        >
          <Stack spacing={2}>
            <Text variant="titleMedium" color={colors.onSurface}>
              {t("providerConfirmationText", {
                providerName: providerName || t("unknownName"),
              })}
            </Text>
            <Text
              variant="titleMedium"
              fontWeight={600}
              color={colors.onSurface}
              component={"span"}
            >
              {t("instructionRequest", {
                providerName: providerName || t("unknownName"),
              })}{" "}
              <Link
                to="https://api.childcarecircuit.org/static/Confirmation%20of%20Provider%20Form.pdf"
                external
                target="_blank"
              >
                <Text
                  variant="titleMedium"
                  fontWeight={600}
                  color={colors.primary}
                  sx={{ textDecoration: "underline" }}
                >
                  {t("formName")}
                </Text>
              </Link>
              .{" "}
              {t("instructionAction", {
                providerName: providerName || t("unknownName"),
                address: "help@portal.childcarecircuit.org",
              })}
            </Text>
            <Stack spacing={1} direction="row" sx={{ alignItems: "center" }}>
              <Divider sx={{ flex: 1 }} />
              <Text variant="titleMedium" sx={{ fontWeight: 600 }}>
                {t("uploadRequiredDocuments")}
              </Text>
              <Divider sx={{ flex: 1 }} />
            </Stack>

            {isUploading && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress
                  thickness={8}
                  size={48}
                  sx={{ color: "#DA2C52" }}
                />
              </div>
            )}
            {!providerConfirmed && (
              <FileUploadBox
                file={null}
                setFile={handleFileSelect}
                disabled={isUploading}
              />
            )}
            {_.uniqBy([...providerConfirmationDocuments], "id").map(
              (document, index) => (
                <Box
                  key={index}
                  sx={{
                    padding: (theme) => theme.spacing(2, 0),
                    borderBottom: "1px solid #CFC8C3",
                  }}
                >
                  {document.file.original_file_name}
                </Box>
              )
            )}
            {providerConfirmed && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Chip
                  icon={<CheckIcon />}
                  label={
                    <Text sx={{ fontWeight: "bold" }}>
                      {t("providerConfirmed")}
                    </Text>
                  }
                  sx={{ backgroundColor: colors.tertiaryContainer }}
                />
              </div>
            )}
          </Stack>
        </Container>
        <Stack
          sx={{
            borderTop: "1px solid " + colors.outline,
            padding: (theme) => theme.spacing(4, 1),
            alignItems: "center",
          }}
        >
          <Link
            to={
              nextPlacementId
                ? reverse(
                    URLS.MISSING_INFORMATION_PROVIDER_CONFIRMATION_FOR_PLACEMENT,
                    {
                      placementId: nextPlacementId,
                      voucherId,
                    }
                  )
                : reverse(URLS.MISSING_INFORMATION_REFERRAL_SUCCESS, {
                    voucherId,
                  })
            }
            style={{ width: matches ? "auto" : "100%" }}
          >
            <BasicButton label={t("next")} />
          </Link>
        </Stack>
      </Stack>
    </FullPageDialog>
  );
};

export default ProviderConfirmationPage;

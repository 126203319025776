import { useState, useEffect } from "react";
import { toast } from "react-toastify";

import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";

import TextField from "components/TextField";
import StyledSelect from "components/StyledSelect";
import Divider from "components/Divider";
import Stack from "components/Stack";
import ContactMethodRow from "components/ContactMethodRow";
import BasicButton from "components/BasicButton";
import FormControl from "components/FormControl";
import Box from "components/Box";
import Text from "components/Text";
import ToggleButton, { ToggleButtonGroup } from "components/ToggleButton";
import Menu from "components/Menu";
import MenuItem from "components/MenuItem";

import { getDefaultLanguageValue } from "utils/language";

import i18n from "i18n/i18n";

import { useLanguageOptions } from "entities/FamilyMember/sdk";
import { IFamilyDetail } from "entities/FamilySpecialist/sdk";
import {
  familyUpdate,
  IFamilyUpdateData,
  PhoneStatus,
  EmailStatus,
  contactMethodOptions,
} from "entities/FamilyEdit/sdk";
import { PreferredContactMethod } from "entities/FamilyEdit/sdk";
import { colors } from "theme";
import { ChatIcon, CheckIcon, MailOutlinedIcon } from "icons";
import { extractErrorMessage } from "utils/error";

interface IProps {
  familyData: IFamilyDetail;
  registerSaveHandler: (handleSave: () => Promise<boolean>) => void;
  refetchFamilyDetail: () => void;
}

type FormInputs = {
  household_id: string;
  family_id: string;
  preferred_contact: string;
  preferred_language: string;
  preferred_contact_method: PreferredContactMethod;
  street_address: string;
  unit_address: string;
  city: string;
  zip_code: string;
};

const ContactInformation = ({
  familyData,
  registerSaveHandler,
  refetchFamilyDetail,
}: IProps) => {
  const { t } = useTranslation();
  const defaultLanguage = getDefaultLanguageValue(i18n.language);
  const languageOptions = useLanguageOptions();
  const { control, getValues } = useForm<FormInputs>({
    defaultValues: {
      household_id: familyData.household_id
        ? familyData.household_id.toString()
        : "",
      family_id: familyData.family_id ? familyData.family_id.toString() : "",
      preferred_contact: familyData.preferred_contact.id.toString(),
      preferred_language:
        familyData.creator.preferred_language || defaultLanguage,
      preferred_contact_method: familyData.creator.preferred_contact_method,
      street_address: familyData.street_address || "",
      unit_address: familyData.unit_address || "",
      city: familyData.city || "",
      zip_code: familyData.zip_code || "",
    },
  });

  const [contactMethods, setContactMethods] = useState<
    {
      method: PhoneStatus | EmailStatus;
      contact: string;
      type: "email" | "phone";
      id?: number;
      error?: string;
    }[]
  >([]);
  const [errors, setErrors] = useState<Partial<FormInputs>>({});
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const contactOptions = familyData.family_members.map((member) => ({
    label: `${member.first_name} ${member.last_name}`,
    value: member.id.toString(),
  }));

  useEffect(() => {
    const initialContactMethods = [
      ...familyData.creator.email_contact_methods.map((email) => ({
        method: email.status as EmailStatus,
        contact: email.email,
        id: email.id,
        type: "email" as const,
        error: "",
      })),
      ...familyData.creator.phone_contact_methods.map((phone) => ({
        method: phone.status as PhoneStatus,
        contact: phone.phone_number,
        id: phone.id,
        type: "phone" as const,
        error: "",
      })),
    ];
    setContactMethods(initialContactMethods);
  }, [familyData]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (type: "email" | "phone") => {
    setContactMethods([
      ...contactMethods,
      {
        method: "ACTIVE" as PhoneStatus | EmailStatus,
        contact: "",
        type,
        error: "",
      },
    ]);
    handleClose();
  };

  const handleContactMethodChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    const newMethods = [...contactMethods];
    newMethods[index][name as "method" | "contact"] = value as
      | PhoneStatus
      | EmailStatus;
    setContactMethods(newMethods);
  };

  const validate = () => {
    const newErrors: Partial<FormInputs> = {};
    let valid = true;

    contactMethods.forEach((method, index) => {
      if (!method.contact) {
        valid = false;
        contactMethods[index].error = t("fieldRequired");
      } else {
        contactMethods[index].error = "";
      }
    });

    setErrors(newErrors);
    setContactMethods([...contactMethods]);
    return valid;
  };

  const handleSave = async () => {
    const values = getValues();
    if (!validate()) {
      return false;
    }

    const updatedData: IFamilyUpdateData = {
      household_id: values.household_id,
      family_id: values.family_id,
      preferred_contact: values.preferred_contact,
      creator: {
        preferred_language: values.preferred_language,
        preferred_contact_method: values.preferred_contact_method,
        phone_contact_methods: contactMethods
          .filter((cm) => cm.type === "phone")
          .map((cm) => ({
            ...(cm.id && { id: cm.id }),
            phone_number: cm.contact,
            status: cm.method as PhoneStatus,
          })),
        email_contact_methods: contactMethods
          .filter((cm) => cm.type === "email")
          .map((cm) => ({
            ...(cm.id && { id: cm.id }),
            email: cm.contact,
            status: cm.method as EmailStatus,
          })),
      },
      street_address: values.street_address,
      unit_address: values.unit_address,
      city: values.city,
      zip_code: values.zip_code,
    };
    let isError = false;

    await familyUpdate(familyData.id, updatedData).catch((error) => {
      const errorMessage = extractErrorMessage(error);
      toast.error(errorMessage);
      isError = true;
    });

    if (!isError) {
      refetchFamilyDetail();
      return true;
    }

    return false;
  };

  useEffect(() => {
    registerSaveHandler(handleSave);
  }, [handleSave]);

  return (
    <>
      <Controller
        name="household_id"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label={t("householdId")}
            fullWidth
            margin="normal"
            placeholder={t("householdId")}
            error={!!errors.household_id}
            helperText={errors.household_id}
          />
        )}
      />
      <Controller
        name="family_id"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label={t("familyId")}
            fullWidth
            margin="normal"
            placeholder={t("familyId")}
            error={!!errors.family_id}
            helperText={errors.family_id}
          />
        )}
      />
      <Controller
        name="preferred_contact"
        control={control}
        render={({ field }) => (
          <StyledSelect
            {...field}
            label={t("preferredContact")}
            options={contactOptions}
            fullWidth
            margin="normal"
            error={!!errors.preferred_contact}
            helperText={errors.preferred_contact}
          />
        )}
      />
      <Controller
        name="preferred_language"
        control={control}
        render={({ field }) => (
          <StyledSelect
            {...field}
            label={t("preferredLanguage")}
            options={languageOptions}
            fullWidth
            margin="normal"
            error={!!errors.preferred_language}
            helperText={errors.preferred_language}
          />
        )}
      />
      <FormControl component="fieldset" margin="normal" sx={{ width: "100%" }}>
        <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
          <Text variant="labelLarge">{t("preferredContactMethod")}</Text>
        </Box>
        <Controller
          name="preferred_contact_method"
          control={control}
          render={({ field: { onChange, value } }) => (
            <ToggleButtonGroup
              value={value}
              exclusive
              onChange={(newValue) => onChange(newValue)}
              fullWidth
              sx={{
                "& .MuiToggleButtonGroup-grouped": {
                  margin: "8px 0",
                  border: `2px solid ${colors.outline}`,
                  textTransform: "capitalize",
                  justifyContent: "flex-start",
                  "&.Mui-selected": {
                    bgcolor: colors.secondaryContainer,
                    color: colors.onSecondaryContainer,
                    "&:hover": {
                      bgcolor: colors.onHoverSecondaryContainer,
                      color: colors.onSecondaryContainer,
                    },
                  },
                  "&:not(:first-of-type)": {
                    borderRadius: "0 50px 50px 0",
                  },
                  "&:first-of-type": {
                    borderRadius: "50px 0 0 50px",
                    borderRight: "1px solid",
                    borderColor: colors.outline,
                  },
                },
              }}
            >
              <ToggleButton
                value="SMS"
                style={{
                  justifyContent: "flex-start",
                  paddingLeft: "30px",
                  height: "40px",
                }}
              >
                {value === "SMS" && (
                  <CheckIcon style={{ marginRight: "15px" }} />
                )}
                {t("textMessage")}
              </ToggleButton>
              <ToggleButton
                value="MAIL"
                style={{
                  justifyContent: "flex-start",
                  paddingLeft: "30px",
                  height: "40px",
                }}
              >
                {value === "MAIL" && (
                  <CheckIcon style={{ marginRight: "15px" }} />
                )}
                {t("email")}
              </ToggleButton>
            </ToggleButtonGroup>
          )}
        />
      </FormControl>
      <Divider />
      <Stack sx={{ mt: "10px", mb: "20px" }}>
        <Stack spacing={2}>
          {contactMethods.map((contactMethod, index) => (
            <ContactMethodRow
              key={index}
              options={contactMethodOptions}
              value={contactMethod.method}
              onChange={(event) => handleContactMethodChange(index, event)}
              placeholder={t(
                contactMethod.type === "email" ? "email" : "phoneNumber"
              )}
              type={contactMethod.type}
              contact={contactMethod.contact}
              error={contactMethod.error}
            />
          ))}
          <Box sx={{ position: "relative" }}>
            <BasicButton
              label={t("addAnotherContactMethod")}
              onClick={handleClick}
              backgroundColor={colors.secondaryContainer}
              color={colors.onSecondaryContainer}
              onHoverBackground={colors.onHoverSecondaryContainer}
              sx={{ width: "100%" }}
            />
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  width: anchorEl ? anchorEl.clientWidth : "auto",
                },
              }}
              MenuListProps={{
                sx: {
                  backgroundColor: colors.surfaceContainerHigh,
                },
              }}
            >
              <MenuItem onClick={() => handleMenuItemClick("email")}>
                <MailOutlinedIcon />{" "}
                <Text variant="titleSmall" sx={{ ml: "10px" }}>
                  {t("email")}
                </Text>
              </MenuItem>
              <MenuItem onClick={() => handleMenuItemClick("phone")}>
                <ChatIcon />{" "}
                <Text variant="titleSmall" sx={{ ml: "10px" }}>
                  {t("phoneNumber")}
                </Text>
              </MenuItem>
            </Menu>
          </Box>
        </Stack>
      </Stack>
      <Controller
        name="street_address"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label={t("addressLine1")}
            fullWidth
            margin="normal"
            placeholder={t("streetName")}
            error={!!errors.street_address}
            helperText={errors.street_address}
          />
        )}
      />
      <Controller
        name="unit_address"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label={t("addressLine2")}
            fullWidth
            margin="normal"
            placeholder={t("addressInfo")}
            error={!!errors.unit_address}
            helperText={errors.unit_address}
          />
        )}
      />
      <Controller
        name="city"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label={t("city")}
            fullWidth
            margin="normal"
            error={!!errors.city}
            helperText={errors.city}
          />
        )}
      />
      <Controller
        name="zip_code"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label={t("zipCode")}
            fullWidth
            margin="normal"
            error={!!errors.zip_code}
            helperText={errors.zip_code || t("zipCodeHelperText")}
          />
        )}
      />
    </>
  );
};

export default ContactInformation;

import { useForm, Controller, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import Stack from "components/Stack";
import StyledSelect from "components/StyledSelect";
import MultiSelect from "components/MultiSelect";
import BasicButton from "components/BasicButton";

import DCFReferral from "entities/FamilyEdit/components/AddAuthorizationForm/components/DCFReferral";
import HomelessReferral from "entities/FamilyEdit/components/AddAuthorizationForm/components/HomelessReferral";
import DTAReferral from "entities/FamilyEdit/components/AddAuthorizationForm/components/DTAReferral";
import IncomeEligible from "entities/FamilyEdit/components/AddAuthorizationForm/components/IncomeEligible";
import Waitlist from "entities/FamilyEdit/components/AddAuthorizationForm/components/Waitlist";

import {
  AuthenticationData,
  useStatusOptions,
  useTypeOptions,
  authorizationCreate,
  authorizationUpdate,
} from "entities/FamilyEdit/sdk";
import { IVoucher } from "entities/FamilySpecialist/sdk";
import { useDocumentsOptions } from "entities/Documents/sdk";
import dayjs from "dayjs";

import theme, { colors } from "theme";
import { extractErrorMessage } from "utils/error";

interface IProps {
  onClose: () => void;
  familyId: number;
  initialData?: IVoucher | null;
  refetchFamilyDetail: () => void;
  children: {
    first_name: string;
    last_name: string;
    id?: number;
  }[];
  setIsSubmitDisabled?: (disabled: boolean) => void;
}

const getDefaultValues = (
  initialData: IVoucher | null | undefined,
  referralSourceOptions: { label: string; value: string }[]
): AuthenticationData => {
  const defaultData: AuthenticationData = {
    ...initialData,
    children: initialData?.children.map((child) => child.id.toString()) || [],
    referral_source:
      initialData?.referral_source || referralSourceOptions[0].value,
    family_has_experienced_domestic_violence:
      initialData?.family_has_experienced_domestic_violence || false,
    family_is_experiencing_homelessness:
      initialData?.family_is_experiencing_homelessness || false,
    parent_has_a_disability: initialData?.parent_has_a_disability || false,
    parent_is_a_member_of_the_us_military:
      initialData?.parent_is_a_member_of_the_us_military || false,
    parent_is_an_early_educator:
      initialData?.parent_is_an_early_educator || false,
    parent_is_receiving_treatment_for_substance_abuse:
      initialData?.parent_is_receiving_treatment_for_substance_abuse || false,
    teen_parent: initialData?.teen_parent || false,
    child_only_needs_coverage_during_school_closures:
      initialData?.child_only_needs_coverage_during_school_closures || false,
    created_at: initialData?.created_at
      ? dayjs(initialData?.created_at).format("MM/DD/YYYY")
      : "",
    household_size: initialData?.household_size,
    total_annual_income: initialData?.total_annual_income,
    annual_income_status: initialData?.annual_income_status || undefined,
    is_issued: initialData?.is_issued || false,
  };

  if (initialData?.referral_date) {
    defaultData.referral_date = dayjs(initialData?.referral_date).format(
      "MM/DD/YYYY"
    );
  }

  if (initialData?.tafdc_closing_date) {
    defaultData.tafdc_closing_date = dayjs(
      initialData?.tafdc_closing_date
    ).format("MM/DD/YYYY");
  }

  return defaultData;
};

const AddAuthorizationForm = ({
  onClose,
  familyId,
  initialData,
  refetchFamilyDetail,
  children,
  setIsSubmitDisabled,
}: IProps) => {
  const { referralSourceOptions } = useDocumentsOptions();
  const { t } = useTranslation();
  const formMethods = useForm<AuthenticationData>({
    defaultValues: getDefaultValues(initialData, referralSourceOptions),
  });
  const {
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
    control,
  } = formMethods;

  const typeOptions = useTypeOptions({
    excludeUnknown: initialData === null,
  });
  const statusOptions = useStatusOptions();
  const type = watch("type");

  const onSubmit = async (data: AuthenticationData) => {
    try {
      if (data.referral_date) {
        data.referral_date = dayjs(data.referral_date).format("YYYY-MM-DD");
      }
      if (data.tafdc_closing_date) {
        data.tafdc_closing_date = dayjs(data.tafdc_closing_date).format(
          "YYYY-MM-DD"
        );
      }

      const saveAuthorization = initialData
        ? () =>
            authorizationUpdate({
              id: familyId,
              voucher_id: initialData.id,
              data,
            })
        : () => authorizationCreate({ id: familyId, data });

      await saveAuthorization();
      await refetchFamilyDetail();
      if (setIsSubmitDisabled) {
        setIsSubmitDisabled(false);
      }
      onClose();
    } catch (error) {
      const errorMessage = extractErrorMessage(error);
      toast.error(errorMessage);
      if (setIsSubmitDisabled) {
        setIsSubmitDisabled(false);
      }
    }
  };

  const childrenOptions = children.map((child) => {
    let label = `${child.first_name} ${child.last_name}`;
    if (label.trim() === "") {
      label = `Unknown Name (ID: ${child.id})`;
    }

    return {
      label,
      value: child.id?.toString() as string,
    };
  });

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)} style={{ height: "100%" }}>
        <Stack sx={{ height: "100%" }} justifyContent={"space-between"}>
          <Stack spacing={2} sx={{ padding: 2 }}>
            <Controller
              name="type"
              control={control}
              rules={{ required: t("fieldRequired") }}
              render={({ field }) => (
                <StyledSelect
                  {...field}
                  label={t("type")}
                  options={typeOptions}
                  fullWidth
                  margin="normal"
                  error={!!errors.type}
                  helperText={errors.type?.message}
                  disabled={!!initialData}
                />
              )}
            />

            <Controller
              name="children"
              control={control}
              rules={{ required: t("fieldRequired") }}
              render={({ field }) => (
                <MultiSelect
                  {...field}
                  label={t("childrenAuthorized")}
                  error={!!errors.children}
                  helperText={errors.children?.message}
                  options={childrenOptions}
                />
              )}
            />

            {initialData && (
              <Controller
                name="status"
                control={control}
                rules={{ required: t("fieldRequired") }}
                render={({ field }) => (
                  <StyledSelect
                    {...field}
                    label={t("status")}
                    options={statusOptions}
                    fullWidth
                    margin="normal"
                    disabled
                  />
                )}
              />
            )}

            {type === "WAITLIST" && (
              <Waitlist
                familyId={familyId}
                showCreatedAt={!!initialData?.created_at}
              />
            )}
            {type === "DTA_REFERRAL" && <DTAReferral />}
            {type === "DCF_REFERRAL" && <DCFReferral />}
            {type === "HOMELESS_REFERRAL" && <HomelessReferral />}
            {type === "INCOME_ELIGIBLE" && initialData && (
              <IncomeEligible familyId={familyId} voucher={initialData} />
            )}
          </Stack>
          <Stack
            sx={{
              width: "100%",
              borderTop: "1px solid " + colors.outline,
              alignItems: "center",
              margin: theme.spacing(4, 0),
            }}
          >
            <Stack sx={{ mt: "30px", width: "100%" }} alignItems="center">
              <BasicButton
                buttonType="submit"
                label={t("save")}
                isDisabled={isSubmitting}
              />
            </Stack>
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  );
};

export default AddAuthorizationForm;

import { useState, useRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import AddButton from "components/AddButton";
import Stack from "components/Stack";
import PlacementItem from "components/PlacementItem";

import EditPanel from "../index";
import { useData } from "../DataContext";
import AddPlacementForm, { AddPlacementFormRef } from "../AddPlacementForm";
import UploadPlacementLetterForm, {
  UploadPlacementLetterFormRef,
} from "entities/FamilyEdit/UploadPlacementLetterForm";
import { IPlacement } from "entities/FamilyEdit/sdk";
import { IVoucher } from "entities/FamilySpecialist/sdk";

import theme from "theme";

interface IProps {
  refetchFamilyDetail: () => void;
}

interface IPlacementWithVoucher extends IPlacement {
  voucher: IVoucher;
}

type PlacementMode = "ADD" | "EDIT" | "UPLOAD";

const PlacementList = ({ refetchFamilyDetail }: IProps) => {
  const { t } = useTranslation();
  const familyData = useData();

  const [openPanel, setOpenPanel] = useState(false);

  const [mode, setMode] = useState<PlacementMode>("ADD");

  const [selectedPlacement, setSelectedPlacement] =
    useState<IPlacementWithVoucher | null>(null);

  const addPlacementFormRef = useRef<AddPlacementFormRef>(null);
  const uploadPlacementFormRef = useRef<UploadPlacementLetterFormRef>(null);

  const handleAddPlacementClick = () => {
    setSelectedPlacement(null);
    setMode("ADD");
    setOpenPanel(true);
  };

  const handleAddPlacementLetterClick = () => {
    setSelectedPlacement(null);
    setMode("UPLOAD");
    setOpenPanel(true);
  };

  const handleEditPlacement = (placement: IPlacementWithVoucher) => {
    setSelectedPlacement(placement);
    setMode("EDIT");
    setOpenPanel(true);
  };

  const handleClosePanel = () => {
    setOpenPanel(false);
  };

  const handleSavePlacement = () => {
    setOpenPanel(false);
    refetchFamilyDetail();
  };

  const handleSaveClick = () => {
    if (mode === "UPLOAD") {
      uploadPlacementFormRef.current?.submitForm();
    } else {
      addPlacementFormRef.current?.submitForm();
    }
  };

  if (!familyData) return null;

  const allPlacements: IPlacementWithVoucher[] = _.orderBy(
    familyData.vouchers
      .map((voucher) =>
        voucher.placements.map((placement) => ({ ...placement, voucher }))
      )
      .flat(),
    "id",
    "desc"
  );

  const panelTitle = useMemo(() => {
    if (mode === "UPLOAD") return "Upload Placement Letter";
    if (mode === "EDIT") return t("editPlacement");
    return t("addPlacement");
  }, [mode, t]);

  return (
    <Stack spacing={2}>
      <EditPanel
        open={openPanel}
        onClose={handleClosePanel}
        title={panelTitle}
        onSaveClick={handleSaveClick}
      >
        {mode === "UPLOAD" ? (
          <UploadPlacementLetterForm
            onSave={handleSavePlacement}
            ref={uploadPlacementFormRef}
            vouchers={familyData.vouchers}
          />
        ) : (
          <AddPlacementForm
            onSave={handleSavePlacement}
            initialData={selectedPlacement}
            ref={addPlacementFormRef}
            familyId={familyData.id}
            refetchFamilyDetail={refetchFamilyDetail}
            vouchers={familyData.vouchers}
            documents={selectedPlacement?.documents}
          />
        )}
      </EditPanel>

      {allPlacements.map((placement) => (
        <PlacementItem
          key={placement.id}
          voucher={placement.voucher}
          placement={placement}
          onEdit={() => handleEditPlacement(placement)}
        />
      ))}

      <Stack
        alignItems="center"
        style={{ marginBottom: theme.spacing(3) }}
        gap={theme.spacing(3)}
      >
        <AddButton
          label={t("addPlacement")}
          onClick={handleAddPlacementClick}
        />
        <AddButton
          label={"Upload Placement Letter"}
          onClick={handleAddPlacementLetterClick}
        />
      </Stack>
    </Stack>
  );
};

export default PlacementList;

import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import Stack from "components/Stack";
import Text from "components/Text";
import BasicButton from "components/BasicButton";
import Container from "components/Container";
import FullPageDialog from "components/FullPageDialog";

import { colors } from "theme";

import CompleteImage from "assets/complete.png";

import { URLS } from "config/urls";
import { useState } from "react";
import { authorizationComplete } from "entities/Authorization/sdk";
import { useMyFamily } from "entities/Family/sdk";

const SuccessPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { voucherId: vouchersIdParam } = useParams<{
    voucherId: string;
  }>();
  const voucherId = parseInt(vouchersIdParam || "");
  const { data: family, mutate: reloadFamily } = useMyFamily();
  const [submitting, setSubmitting] = useState(false);
  const voucher = family?.vouchers.find((v) => v.id === voucherId);

  const submitForReview = async () => {
    setSubmitting(true)
    await authorizationComplete({ voucherId });
    await reloadFamily ()
    setSubmitting(false)
    navigate(URLS.HOME);
  };

  const goToHomePage = () => {
    navigate(URLS.HOME);
  };

  return (
    <FullPageDialog title={t("successScreenTitle")} closeUrl={URLS.HOME}>
      <Stack
        sx={{
          justifyContent: "space-between",
          flex: 1,
        }}
      >
        <Container
          maxWidth="sm"
          sx={{
            margin: "30px auto",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            flex: 1,
          }}
        >
          <img
            src={CompleteImage}
            alt="Completed"
            style={{ maxWidth: "400px" }}
          />
          <Text
            variant="titleMedium"
            color={colors.onSurface}
            sx={{ margin: (theme) => theme.spacing(6, 0, 2, 0) }}
          >
            {t("docuSignExplanation")}
          </Text>
          <Text variant="titleMedium" color={colors.onSurface}>
            {t("docuSignFollowUp")}
          </Text>
        </Container>
        <Stack
          spacing={2}
          sx={{
            padding: (theme) => theme.spacing(4, 1),
            alignItems: "center",
          }}
        >
          {voucher?.status === "INCOMPLETE_IE_PROFILE" && (
            <BasicButton
              label={t("finishLater")}
              backgroundColor={colors.secondaryContainer}
              color={colors.onSecondaryContainer}
              onHoverBackground={colors.onHoverSecondaryContainer}
              onClick={goToHomePage}
            />
          )}
          <BasicButton
            label={t("submitForReview")}
            backgroundColor={colors.secondaryContainer}
            color={colors.onSecondaryContainer}
            onHoverBackground={colors.onHoverSecondaryContainer}
            onClick={submitForReview}
            disabled={submitting}
          />
        </Stack>
      </Stack>
    </FullPageDialog>
  );
};

export default SuccessPage;

import { useTranslation } from "react-i18next";

import { useDropzone } from "react-dropzone";

import { styled } from "@mui/system";

import Box from "components/Box";
import Text from "components/Text";
import IconButton from "components/IconButton";

import { colors } from "theme";
import { CloseIcon, InsertDriveFileIcon } from "icons";

const StyledDropzone = styled("div")<{ disabled?: boolean }>(
  ({ theme, disabled }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(2),
    border: `1px dashed ${colors.outlineVariant}`,
    borderRadius: "8px",
    backgroundColor: colors.surfaceContainer,
    outline: "none",
    width: "100%",
    textAlign: "center",
    cursor: disabled ? "not-allowed" : "pointer",
    opacity: disabled ? 0.5 : 1,
  })
);

const UploadedFileBox = styled(Box)(({}) => ({
  display: "flex",
  alignItems: "center",
  padding: "8px 16px",
  backgroundColor: colors.surfaceContainer,
  borderRadius: "8px",
}));

interface FileUploadBoxProps {
  file: File | null;
  setFile: (file: File | null) => void;
  disabled?: boolean;
}

function FileUploadBox({
  file,
  setFile,
  disabled = false,
}: FileUploadBoxProps) {
  const { t } = useTranslation();

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    disabled,
    onDrop: (acceptedFiles) => {
      if (!disabled && acceptedFiles.length > 0) {
        const newFile = acceptedFiles[0];
        setFile(
          Object.assign(newFile, {
            preview: URL.createObjectURL(newFile),
          })
        );
      }
    },
  });

  const handleRemoveFile = () => {
    setFile(null);
  };

  return (
    <Box sx={{ width: "100%" }}>
      {file ? (
        <UploadedFileBox>
          <InsertDriveFileIcon color="action" />
          <Text
            variant="labelLarge"
            color={colors.onSurfaceVariant}
            sx={{ flexGrow: 1, ml: 1, fontWeight: "500" }}
          >
            {file.name}
          </Text>
          <IconButton
            onClick={handleRemoveFile}
            size="small"
            disabled={disabled}
          >
            <CloseIcon style={{ fill: colors.onSurfaceVariant }} />
          </IconButton>
        </UploadedFileBox>
      ) : (
        <StyledDropzone {...getRootProps()} disabled={disabled}>
          <input {...getInputProps()} />
          <Text
            variant="titleSmall"
            style={{ marginBottom: "8px" }}
            color={colors.onSurfaceVariant}
          >
            {t("dragFileHereOr")}
          </Text>
          <Box component="span">
            <Text
              variant="labelLarge"
              color={disabled ? colors.onSurfaceVariant : colors.primary}
            >
              {t("uploadAFile")}
            </Text>
          </Box>
        </StyledDropzone>
      )}
    </Box>
  );
}

export default FileUploadBox;

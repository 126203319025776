const BASE_BACKEND_URL = import.meta.env.VITE_BASE_BACKEND_URL;
export const BASE_URL = `${BASE_BACKEND_URL}`;

export enum URLS {
  INITIAL = "/",
  LOGIN = "/login",
  HOME = "/home",
  SIGNUP = "/signup",
  GET_HELP = "/get-help",
  THANK_YOU = "/thank-you",
  REGISTRATION_VERIFY = "/registration-verify",
  LOGIN_VERIFY = "/login-verify",
  CHILD_CARE_NEEDS = "/child-care-needs",
  CHILD_CARE_NEEDS_DESCRIPTION = "/child-care-needs/description",
  CHILD_CARE_NEEDS_CHECK_NEEDS = "/child-care-needs/check-needs",
  CHILD_CARE_NEEDS_UPDATE_INFORMATION = "/child-care-needs/update-information",
  FAMILY_INFORMATION = "/family-information",
  MAGIC_LINK = "/magic-link/:magicToken",
  EXPIRED_MAGIC_LINK = "/expired-magic-link",
  FAMILY_INFORMATION_OVERVIEW = "/family-information/overview",
  FAMILY_INFORMATION_ABOUT_YOU = "/family-information/about-you",
  FAMILY_INFORMATION_YOUR_STATUS = "/family-information/your-status",
  FAMILY_INFORMATION_PRIMARY_CONTACT = "/family-information/primary-contact",
  FAMILY_INFORMATION_PARENTS = "/family-information/parents",
  FAMILY_INFORMATION_ADD_PARENT = "/family-information/add-parent",
  FAMILY_INFORMATION_EDIT_PARENT = "/family-information/parent/:parentId/edit",
  FAMILY_INFORMATION_MAILING_ADDRESS = "/family-information/mailing-address",
  FAMILY_INFORMATION_CHILDREN = "/family-information/children",
  FAMILY_INFORMATION_ADD_CHILD = "/family-information/add-child",
  FAMILY_INFORMATION_EDIT_CHILD = "/family-information/child/:childId/edit",
  FAMILY_INFORMATION_INCOME_VERIFICATION = "/family-information/income-verification",
  FAMILY_INFORMATION_HOUSEHOLD_DEFINITIONS = "/family-information/household-definitions",
  FAMILY_INFORMATION_SPECIAL_CIRCUMSTANCES = "/family-information/special-circumstances",
  FAMILY_INFORMATION_ADDITIONAL_INFORMATION = "/family-information/additional-information",
  FAMILY_INFORMATION_COMPLETE = "/family-information/complete",

  MISSING_INFORMATION_PRIMARY_CONTACT = "/missing-information/:voucherId/primary-contact",
  MISSING_INFORMATION_PARENTS = "/missing-information/:voucherId/parents",
  MISSING_INFORMATION_ADD_PARENT = "/missing-information/:voucherId/add-parent",
  MISSING_INFORMATION_EDIT_PARENT = "/missing-information/:voucherId/parent/:parentId/edit",
  MISSING_INFORMATION_CHILDREN = "/missing-information/:voucherId/children",
  MISSING_INFORMATION_ADD_CHILD = "/missing-information/:voucherId/add-child",
  MISSING_INFORMATION_EDIT_CHILD = "/missing-information/:voucherId/child/:childId/edit",
  MISSING_INFORMATION_VERIFICATION = "/missing-information/:voucherId/verification",
  MISSING_INFORMATION_VERIFICATION_FOR_PARENT = "/missing-information/:voucherId/verification/parent/:parentId",
  MISSING_INFORMATION_AUTHORIZATION_LIST = "/missing-information/:voucherId/authorizations",
  MISSING_INFORMATION_AUTHORIZATION_EDIT = "/missing-information/:voucherId/authorization/:authorizationId/edit",
  MISSING_INFORMATION_PLACEMENT_LIST = "/missing-information/:voucherId/placements",
  MISSING_INFORMATION_PLACEMENT_ADD = "/missing-information/:voucherId/placements/add",
  MISSING_INFORMATION_PLACEMENT_EDIT = "/missing-information/:voucherId/placement/:placementId/edit",
  MISSING_INFORMATION_PROVIDER_CONFIRMATION = "/missing-information/:voucherId/provider-confirmation",
  MISSING_INFORMATION_PROVIDER_CONFIRMATION_FOR_PLACEMENT = "/missing-information/:voucherId/provider-confirmation/placement/:placementId",
  MISSING_INFORMATION_INCOME_VERIFICATION = "/missing-information/:voucherId/income-verification",
  MISSING_INFORMATION_SPECIAL_CIRCUMSTANCES = "/missing-information/:voucherId/special-circumstances",
  MISSING_INFORMATION_REFERRAL_SUCCESS = "/missing-information/:voucherId/referral-success",
  MISSING_INFORMATION_WAITLIST_SUCCESS = "/missing-information/:voucherId/waitlist-success",

  VERIFY_INCOME_PRIMARY_CONTACT = "/verify-income/:voucherId/primary-contact",
  VERIFY_INCOME_CHILD_CARE_NEEDS = "/verify-income/:voucherId/child-care-needs",
  VERIFY_INCOME_OVERVIEW = "/verify-income/:voucherId/overview",
  VERIFY_INCOME_PARENTS_LIST = "/verify-income/:voucherId/parents-list",
  VERIFY_INCOME_CHILDREN_LIST = "/verify-income/:voucherId/children-list",
  VERIFY_INCOME_EDIT_PARENT = "/verify-income/:voucherId/parent/:parentId/edit",
  VERIFY_INCOME_EDIT_CHILD = "/verify-income/:voucherId/child/:childId/edit",
  VERIFY_INCOME_ADD_PARENT = "/verify-income/:voucherId/add-parent",
  VERIFY_INCOME_ADD_CHILD = "/verify-income/:voucherId/add-child",
  VERIFY_INCOME_DEPENDENTS_LIST = "/verify-income/:voucherId/dependents-list",
  VERIFY_INCOME_EDIT_DEPENDENT = "/verify-income/:voucherId/dependent/:dependentId/edit",
  VERIFY_INCOME_ADD_DEPENDENT = "/verify-income/:voucherId/add-dependent",
  VERIFY_INCOME_VERIFICATION_FOR_PARENT = "/verify-income/:voucherId/verification/parent/:parentId",
  VERIFY_INCOME_CITIZENSHIP_FOR_CHILD = "/verify-income/:voucherId/verification/child-citizenship/:childId",
  VERIFY_INCOME_MILITARY_OR_RETIRED = "/verify-income/:voucherId/military-or-retired",
  VERIFY_INCOME_RELATIONSHIP_FOR_CHILD = "/verify-income/:voucherId/verification/child-relationship/:childId",
  VERIFY_INCOME_AGE_FOR_CHILD = "/verify-income/:voucherId/verification/child-age/:childId",
  VERIFY_INCOME_RESIDENCY_VERIFICATION = "/verify-income/:voucherId/verification/residency",
  VERIFY_PARENT_ELIGIBILITY = "/verify-income/:voucherId/verify-eligibility/:parentId",
  VERIFY_INCOME_PARENT_OTHER_INCOME = "/verify-income/:voucherId/other-income/:parentId",
  VERIFY_INCOME_PARENT_RENTAL_INCOME = "/verify-income/:voucherId/rental-income/:parentId",
  VERIFY_INCOME_PARENT_LOTTERY = "/verify-income/:voucherId/lottery-earnings/:parentId",
  VERIFY_INCOME_PARENT_ALIMONY = "/verify-income/:voucherId/alimony/:parentId",
  VERIFY_INCOME_PARENT_WORKERS_COMPENSATION = "/verify-income/:voucherId/workers-compensation/:parentId",
  VERIFY_INCOME_PARENT_UNEMPLOYMENT = "/verify-income/:voucherId/unemployment/:parentId",
  VERIFY_INCOME_PARENT_RETIREMENT = "/verify-income/:voucherId/retirement/:parentId",
  VERIFY_INCOME_VERIFICATION_MILITARY = "/verify-income/:voucherId/verification/:parentId/military",
  VERIFY_INCOME_VERIFICATION_RETIREMENT = "/verify-income/:voucherId/verification/:parentId/retirement",
  VERIFY_INCOME_VERIFICATION_SCHOOL_OR_TRAINING = "/verify-income/:voucherId/verification/:parentId/school-or-training",
  VERIFY_INCOME_SEEKING_ACTIVITY = "/verify-income/:voucherId/seeking-activity/:parentId",
  VERIFY_INCOME_EMPLOYMENT = "/verify-income/:voucherId/employment/:parentId",
  VERIFY_INCOME_VERIFY_SELF_EMPLOYMENT_INCOME = "/verify-income/:voucherId/verification/:parentId/self-employment-income",
  VERIFY_INCOME_VERIFY_SELF_EMPLOYMENT_INCOME_FORM = "/verify-income/:voucherId/verification/:parentId/self-employment-income-form",
  VERIFY_INCOME_VERIFY_SELF_EMPLOYMENT_INCOME_UPLOAD = "/verify-income/:voucherId/verification/:parentId/self-employment-income-upload",
  VERIFY_INCOME_VERIFY_BUSINESS = "/verify-income/:voucherId/verification/:parentId/business",
  VERIFY_INCOME_VERIFY_INFORMATION = "/verify-income/:voucherId/verify-information",
  VERIFY_INCOME_PLACEMENT_LIST = "/verify-income/:voucherId/placement-list",
  VERIFY_INCOME_PLACEMENT_ADD = "/verify-income/:voucherId/placement/add",
  VERIFY_INCOME_PLACEMENT_EDIT = "/verify-income/:voucherId/placement/:placementId/edit",
  VERIFY_INCOME_PROVIDER_CONFIRMATION = "/verify-income/:voucherId/provider-confirmation/placement/:placementId",
  VERIFY_INCOME_SUCCESS = "/verify-income/:voucherId/success",

  // Specialist URLs
  FAMILY_SPECIALIST_FAMILY_LIST = "/family-specialist/family-list",
  FAMILY_SPECIALIST_FAMILY_DETAIL = "/family-specialist/family-detail/:id",
  FAMILY_SPECIALIST_AUTHORIZATION = "/family-specialist/family-information/authorization",
  FAMILY_SPECIALIST_PRIMARY_CONTACT = "/family-specialist/family-information/family/primary-contact",
  FAMILY_SPECIALIST_INCOME_ELIGIBLE_PRIMARY_CONTACT = "/family-specialist/family-information/family/income-eligible/primary-contact",
  FAMILY_SPECIALIST_YOUR_STATUS = "/family-specialist/family-information/family/:familyId/your-status",
  FAMILY_SPECIALIST_PARENTS = "/family-specialist/family-information/family/:familyId/parents",
  FAMILY_SPECIALIST_INCOME_ELIGIBLE_PARENTS = "/family-specialist/family-information/family/:familyId/income-eligible/parents",
  FAMILY_SPECIALIST_ADD_PARENT = "/family-specialist/family-information/family/:familyId/add-parent",
  FAMILY_SPECIALIST_INCOME_ELIGIBLE_ADD_PARENT = "/family-specialist/family-information/family/:familyId/income-eligible/add-parent",
  FAMILY_SPECIALIST_MAILING_ADDRESS = "/family-specialist/family-information/family/:familyId/mailing-address",
  FAMILY_SPECIALIST_CHILDREN = "/family-specialist/family-information/family/:familyId/children",
  FAMILY_SPECIALIST_INCOME_ELIGIBLE_CHILDREN = "/family-specialist/family-information/family/:familyId/income-eligible/children",
  FAMILY_SPECIALIST_ADD_CHILD = "/family-specialist/family-information/family/:familyId/add-child",
  FAMILY_SPECIALIST_INCOME_ELIGIBLE_ADD_CHILD = "/family-specialist/family-information/family/:familyId/income-eligible/add-child",
  FAMILY_SPECIALIST_SPECIAL_CIRCUMSTANCES = "/family-specialist/family-information/family/:familyId/special-circumstances",
  FAMILY_SPECIALIST_ADDITIONAL_INFORMATION = "/family-specialist/family-information/family/:familyId/additional-information",
  FAMILY_SPECIALIST_INCOME_ELIGIBLE_ADDITIONAL_INFORMATION = "/family-specialist/family-information/family/:familyId/income-eligible/additional-information",
  FAMILY_SPECIALIST_COMPLETE = "/family-specialist/family-information/family/:familyId/complete",
  FAMILY_SPECIALIST_DOCUMENTS = "/family-specialist/family-information/documents/:documentId/:stepname",
  FAMILY_SPECIALIST_DOCUMENTS_ADD_PARENT = "/family-specialist/family-information/documents/:documentId/parents/add",
  FAMILY_SPECIALIST_DOCUMENTS_EDIT_PARENT = "/family-specialist/family-information/documents/:documentId/parents/:parentId/edit",
  FAMILY_SPECIALIST_DOCUMENTS_ADD_CHILD = "/family-specialist/family-information/documents/:documentId/parsed-child/add",
  FAMILY_SPECIALIST_DOCUMENTS_EDIT_CHILD = "/family-specialist/family-information/documents/:documentId/parsed-child/:parsedChildId/edit",
  FAMILY_SPECIALIST_DOCUMENTS_ADD_PLACEMENT = "/family-specialist/family-information/documents/:documentId/placement/add",
  FAMILY_SPECIALIST_DOCUMENTS_EDIT_PLACEMENT = "/family-specialist/family-information/documents/:documentId/placement/:placementId/edit",
  FAMILY_SPECIALIST_FAMILY_ASSIGN_SPECIALIST = "/family-specialist/family-information/documents/:documentId/family/:familyId/assign-specialist",
  FAMILY_SPECIALIST_MESSAGES_LIST = "/family-specialist/messages-list",
  FAMILY_SPECIALIST_ADMIN = "/family-specialist/admin",
}

import { useEffect, useState, useRef, memo } from "react";
import { useParams } from "react-router-dom";
import { Document, Page } from "react-pdf";

import _ from "lodash";

import Box from "components/Box";
import { useDetailDocumentData } from "entities/Documents/sdk";

const MemoizedPdfDocument = memo(function MemoizedPdfDocument({
  fileBlobUrl,
  numPages,
  onLoadSuccess,
}: {
  fileBlobUrl: string | null;
  numPages: number | null;
  onLoadSuccess: (params: { numPages: number }) => void;
}) {
  return (
    <Document
      file={fileBlobUrl}
      onLoadSuccess={onLoadSuccess}
      loading={<div>Loading PDF...</div>}
    >
      {numPages
        ? _.range(1, numPages + 1).map((pageNumber) => (
            <Page
              key={`page_${pageNumber}`}
              pageNumber={pageNumber}
              scale={1.5}
              width={600}
              renderTextLayer={false}
              renderAnnotationLayer={false}
            />
          ))
        : null}
    </Document>
  );
});

const LeftPanel = () => {
  const { documentId: documentIdParam } = useParams<{ documentId: string }>();
  const documentId = parseInt(String(documentIdParam));

  const { data } = useDetailDocumentData(documentId);
  const fileUrl = data?.file.file;
  const fileType = data?.file.file_type;

  const [numPages, setNumPages] = useState<number | null>(null);
  const [fileBlobUrl, setFileBlobUrl] = useState<string | null>(null);

  const pdfCacheRef = useRef<Record<number, string>>({});

  useEffect(() => {
    if (!fileUrl || fileType !== "application/pdf") {
      setFileBlobUrl(null);
      return;
    }

    if (pdfCacheRef.current[documentId]) {
      setFileBlobUrl(pdfCacheRef.current[documentId]);
      return;
    }

    let isCanceled = false;
    setFileBlobUrl(null);

    fetch(fileUrl)
      .then((res) => res.blob())
      .then((blob) => {
        if (!isCanceled) {
          const blobUrl = URL.createObjectURL(blob);
          pdfCacheRef.current[documentId] = blobUrl;
          setFileBlobUrl(blobUrl);
        }
      });

    return () => {
      isCanceled = true;
      if (fileBlobUrl) {
        URL.revokeObjectURL(fileBlobUrl);
      }
    };
  }, [fileUrl, fileType, documentId, fileBlobUrl]);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  return (
    <Box
      sx={{
        flex: 2,
        position: "sticky",
        top: 70,
        maxHeight: "calc(100vh - 140px)",
        overflowY: "auto",
        display: "flex",
        justifyContent: "center",
      }}
    >
      {fileType === "application/pdf" ? (
        <MemoizedPdfDocument
          fileBlobUrl={fileBlobUrl}
          numPages={numPages}
          onLoadSuccess={onDocumentLoadSuccess}
        />
      ) : (
        <img
          src={fileUrl}
          alt="Uploaded File"
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
            display: "block",
          }}
        />
      )}
    </Box>
  );
};

export default LeftPanel;

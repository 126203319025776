import { useState } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Controller, useForm } from "react-hook-form";

import useMediaQuery from "components/useMediaQuery";
import Box from "components/Box";
import Stack from "components/Stack";
import BasicButton from "components/BasicButton";
import Text from "components/Text";
import List, { ListItem } from "components/List";
import TextField from "components/TextField";
import FormHelperText from "components/FormHelperText";

import SuccessfulCreation from "assets/successful-creation.png";

import { URLS } from "config/urls";

import { childCareNeedsUpdate } from "entities/ChildCareNeeds/sdk";

import { colors } from "theme";

const ChildNeedsForm = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    control,
    watch,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      childCareNeeds: "",
    },
  });
  const childCareNeedsData = watch("childCareNeeds");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const matches = useMediaQuery("(min-width:600px)");

  const onSubmit = () => {
    if (!childCareNeedsData) {
      setError("childCareNeeds", {
        type: "manual",
        message: t("fieldRequired"),
      });
      return;
    }

    setIsSubmitting(true);
    childCareNeedsUpdate({ child_care_need_summary: childCareNeedsData })
      .then(() => {
        setIsSubmitting(false);
        navigate(URLS.CHILD_CARE_NEEDS_CHECK_NEEDS);
      })
      .catch(() => {
        const errorMessage = t("errorSavingData");
        toast.error(errorMessage);
        setIsSubmitting(false);
      });
  };

  return (
    <>
      <Box sx={{ width: matches ? "50%" : "auto", alignSelf: "center" }}>
        <Stack alignItems={"center"} sx={{ marginBottom: "30px" }}>
          <img
            src={SuccessfulCreation}
            alt="Successful Creation"
            style={{ width: matches ? "138px" : "105px" }}
          />
          <Text
            variant="headlineMedium"
            sx={{ mt: 2, mb: 1, color: colors.primary, textAlign: "center" }}
          >
            {t("accountCreationSuccessful")}
          </Text>
        </Stack>
        <Stack alignItems={"center"}>
          <Text variant="titleLarge" sx={{ mt: 2, mb: 1, textAlign: "center" }}>
            {t("describeChildCareNeeds")}
          </Text>
          <Text
            variant="titleMedium"
            sx={{ mt: 1, mb: 2, textAlign: "center" }}
          >
            {t("forExample")}
            <List sx={{ listStyleType: "disc", pl: 2 }}>
              <ListItem sx={{ display: "list-item", p: 0 }}>
                {t("exampleQuestion1")}
              </ListItem>
              <ListItem sx={{ display: "list-item", p: 0 }}>
                {t("exampleQuestion2")}
              </ListItem>
              <ListItem sx={{ display: "list-item", p: 0 }}>
                {t("exampleQuestion3")}
              </ListItem>
            </List>
          </Text>
          <Controller
            name="childCareNeeds"
            control={control}
            render={({ field }) => (
              <>
                <TextField
                  {...field}
                  fullWidth
                  multiline
                  minRows={3}
                  variant="outlined"
                  label={t("describeWhatYouNeddHelpWIth")}
                  sx={{ mb: 0, maxWidth: "420px" }}
                  error={!!errors.childCareNeeds}
                />
                {!!errors.childCareNeeds && (
                  <FormHelperText error>
                    {errors.childCareNeeds.message}
                  </FormHelperText>
                )}
              </>
            )}
          />
        </Stack>
      </Box>
      <Stack
        sx={{
          borderTop: matches ? "1px solid" : "none",
          borderColor: colors.outline,
        }}
      >
        <Stack
          gap="20px"
          alignItems="center"
          sx={{
            textAlign: "right",
            mt: "30px",
          }}
        >
          <BasicButton
            label={t("next")}
            onClick={handleSubmit(onSubmit)}
            isDisabled={isSubmitting}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default ChildNeedsForm;

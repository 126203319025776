import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import dayjs from "dayjs";
import { toast } from "react-toastify";

import Stack from "components/Stack";
import TextField from "components/TextField";
import StyledSelect from "components/StyledSelect";
import BasicButton from "components/BasicButton";
import useMediaQuery from "components/useMediaQuery";
import DateInput from "components/DateInput";
import MultiSelect from "components/MultiSelect";

import {
  parsedVoucherUpdate,
  IDetailDocumentData,
  useDocumentsOptions,
} from "entities/Documents/sdk";

import { colors } from "theme";
import { URLS } from "config/urls";
import { reverse } from "utils/urls";

interface IProps {
  data: IDetailDocumentData;
  documentId: number;
}
const DTAReferralAuthorization = ({ data, documentId }: IProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    authorizationTypeOptions,
    childCareStatusOptions,
    dtaProgramOptions,
    serviceNeedOptions,
  } = useDocumentsOptions();
  const matches = useMediaQuery("(min-width:600px)");

  const dta_data = data.family_data.voucher_data;

  const { control, getValues } = useForm({
    defaultValues: {
      authorizationType: data.document_type,
      referralDate: dayjs(dta_data?.referral_date || new Date()).format(
        "MM/DD/YYYY"
      ),
      referralNumber: dta_data?.dta_referral_number || "",
      CCRR: dta_data?.ccrr || "",
      dtaProgram: dta_data?.dta_program || "",
      serviceNeed: dta_data?.service_need || "",
      dta_referral_type: dta_data?.dta_referral_type || "",
      tafdc_closing_date: dta_data?.tafdc_closing_date
        ? dayjs(dta_data?.tafdc_closing_date).format("MM/DD/YYYY")
        : "",
      issuing_location: dta_data?.issuing_location || "",
      childCareStatus: dta_data?.child_care_status || "",
      children: data.family_data.voucher_data.children.map((child) =>
        String(child.id)
      ),
    },
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSave = () => {
    if (dta_data) {
      const values = getValues();

      const dtaData = {
        authorization_type: data.document_type,
        referral_date: values.referralDate
          ? dayjs(values?.referralDate, "MM/DD/YYYY").format("YYYY-MM-DD")
          : undefined,
        dta_referral_number: values.referralNumber,
        ccrr: values.CCRR,
        dta_program: values.dtaProgram,
        service_need: values.serviceNeed,
        child_care_status: values.childCareStatus,
        dta_referral_type: values.dta_referral_type,
        tafdc_closing_date: values.tafdc_closing_date
          ? dayjs(values.tafdc_closing_date).format("YYYY-MM-DD")
          : undefined,
        issuing_location: values.issuing_location,
        children: values.children,
      };

      setIsSubmitting(true);
      parsedVoucherUpdate({ parsedVoucherId: dta_data.id, data: dtaData })
        .then(() => {
          setIsSubmitting(false);
          navigate(
            reverse(URLS.FAMILY_SPECIALIST_DOCUMENTS, {
              documentId: String(documentId),
              stepname: "placement",
            })
          );
        })
        .catch(() => {
          const errorMessage = t("errorSavingData");
          toast.error(errorMessage);
          setIsSubmitting(false);
        });
    }
  };

  const childrenOptions = data.family_data.children_data.map((child) => ({
    label: `${child.first_name} ${child.last_name}`,
    value: String(child.id),
  }));

  return (
    <>
      <form>
        <Stack spacing={2}>
          <Controller
            name="children"
            control={control}
            rules={{ required: t("fieldRequired") }}
            render={({ field }) => (
              <MultiSelect
                {...field}
                label={t("childrenAuthorized")}
                options={childrenOptions}
              />
            )}
          />
          <Controller
            name="authorizationType"
            control={control}
            render={({ field }) => (
              <StyledSelect
                {...field}
                label={t("authorizationType")}
                options={authorizationTypeOptions}
                disabled
              />
            )}
          />

          <DateInput
            label={t("referralDate")}
            name="referralDate"
            control={control}
          />
          <Controller
            name="childCareStatus"
            control={control}
            render={({ field }) => (
              <StyledSelect
                {...field}
                label={t("childCareStatus")}
                options={childCareStatusOptions}
              />
            )}
          />
          <Controller
            name="referralNumber"
            control={control}
            render={({ field }) => (
              <TextField {...field} label={t("referralNumber")} />
            )}
          />
          <Controller
            name="CCRR"
            control={control}
            render={({ field }) => <TextField {...field} label={t("CCRR")} />}
          />
          <Controller
            name="dtaProgram"
            control={control}
            render={({ field }) => (
              <TextField {...field} label={t("dtaProgram")} />
            )}
          />
          <Controller
            name="dta_referral_type"
            control={control}
            render={({ field }) => (
              <StyledSelect
                {...field}
                label={t("childCareReferralType")}
                options={dtaProgramOptions}
              />
            )}
          />
          <DateInput
            label={t("tafdcBenefitClosingDate")}
            name="tafdc_closing_date"
            control={control}
          />
          <Controller
            name="issuing_location"
            control={control}
            render={({ field }) => (
              <TextField {...field} label={t("localDtaOffice")} />
            )}
          />
          <Controller
            name="serviceNeed"
            control={control}
            render={({ field }) => (
              <StyledSelect
                {...field}
                label={t("serviceNeed")}
                options={serviceNeedOptions}
              />
            )}
          />
        </Stack>
      </form>
      <Stack
        sx={{
          borderTop: matches ? "1px solid" : "none",
          borderColor: colors.outline,
        }}
      >
        <Stack
          gap="20px"
          alignItems="center"
          sx={{
            textAlign: "right",
            mt: "30px",
          }}
        >
          <BasicButton
            label={t("save")}
            onClick={handleSave}
            isDisabled={isSubmitting}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default DTAReferralAuthorization;

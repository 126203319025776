import { useState } from "react";

import { useNavigate } from "react-router-dom";
import { useForm, SubmitHandler, Controller } from "react-hook-form";

import { URLS } from "config/urls";

import { useTranslation } from "react-i18next";

import BasicButton from "components/BasicButton";
import Stack from "components/Stack";
import Text from "components/Text";
import TextField from "components/TextField";
import Box from "components/Box";
import FormLabel from "components/FormLabel";
import useMediaQuery from "components/useMediaQuery";
import Link from "components/Link";

import { login } from "entities/Auth/sdk";
import { phoneMask } from "utils/masks";

import { colors } from "theme";

type FormInputs = {
  email: string;
  phone_number?: string;
};

const LoginPage = () => {
  const navigate = useNavigate();
  const matches = useMediaQuery("(min-width:600px)");
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    control,
    handleSubmit,
    setError,
    watch,
    formState: { errors },
  } = useForm<FormInputs>();
  const watchData = watch();
  const isButtonDisabled =
    (!watchData.email && !watchData.phone_number) || isSubmitting;

  const cleanPhoneNumber = (phoneNumber: string) => {
    return phoneNumber.replace(/\D/g, "");
  };

  const onSubmit: SubmitHandler<FormInputs> = (data) => {
    setIsSubmitting(true);
    const cleanedPhoneNumber = data.phone_number
      ? `+${cleanPhoneNumber(data.phone_number)}`
      : undefined;
    const loginData = {
      email: data.email || undefined,
      phone_number: cleanedPhoneNumber,
    };

    login(loginData)
      .then((response) => {
        navigate(URLS.LOGIN_VERIFY, {
          state: {
            emailOrPhone: data.email || cleanedPhoneNumber,
            magicToken: response.magic_token,
            userType: response.user_type,
          },
        });
        setIsSubmitting(false);
      })
      .catch((error) => {
        setError("root", {
          message: (error.detail?.non_field_errors as string[])?.join(" "),
        });
        setIsSubmitting(false);
      });
  };

  const goToSignUp = () => {
    navigate(URLS.SIGNUP);
  };

  return (
    <Stack
      spacing={2}
      direction="column"
      gap="20px"
      alignItems="center"
      sx={{ height: "calc(100vh - 180px)" }}
    >
      {!errors.root && <Text variant="titleLarge">{t("getYouSignedIn")}</Text>}
      {errors.root && <Text variant="titleLarge">{errors.root.message}</Text>}
      {errors.root && <Text variant="titleLarge">{t("tryAnother")}</Text>}
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          width: matches ? "auto" : "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Stack spacing={2} direction="column">
          <Box>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("email")}
                  fullWidth
                  margin="normal"
                  disabled={!!watchData.phone_number}
                />
              )}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              m: "10px 0",
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                height: "1px",
                bgcolor: colors.outlineVariant,
              }}
            />
            <FormLabel component="legend" style={{ padding: "0 10px" }}>
              <Text variant="titleMedium" color={colors.onSurface}>
                or
              </Text>
            </FormLabel>
            <Box
              sx={{
                flexGrow: 1,
                height: "1px",
                bgcolor: colors.outlineVariant,
              }}
            />
          </Box>
          <Box>
            <Controller
              name="phone_number"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("phoneNumber")}
                  fullWidth
                  margin="normal"
                  inputRef={(ref) => {
                    field.ref(ref);
                    phoneMask(ref);
                  }}
                  disabled={!!watchData.email}
                />
              )}
            />
          </Box>
        </Stack>
        {errors.root && (
          <Link to={URLS.GET_HELP} style={{ alignSelf: "center" }}>
            <Text>
              <Text variant="titleMedium" color={colors.primary}>
                {t("needHelp")}
              </Text>
            </Text>
          </Link>
        )}
        <Stack sx={{ mt: "30px" }} alignItems="flex-end" gap="20px">
          {errors.root && (
            <BasicButton
              label={t("createNewAccount")}
              isDisabled={isSubmitting}
              onClick={goToSignUp}
              backgroundColor={colors.secondaryContainer}
              color={colors.onSecondaryContainer}
              onHoverBackground={colors.onHoverSecondaryContainer}
            />
          )}
          <BasicButton
            label={t("signIn")}
            buttonType="submit"
            isDisabled={isButtonDisabled}
          />
        </Stack>
      </form>
    </Stack>
  );
};

export default LoginPage;

import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import Box from "components/Box";
import Text from "components/Text";
import Stack from "components/Stack";

const RegistrationVerificationPage = () => {
  const location = useLocation();
  const { emailOrPhone } = location.state || {};
  const { t } = useTranslation();

  return (
    <Box sx={{ padding: "10px" }}>
      <Stack>
        <Text
          variant="titleLarge"
          sx={{ textAlign: "center", wordBreak: "break-word" }}
        >
          {t("weSentLink", { contactMethod: emailOrPhone })}
        </Text>
        <Text variant="titleLarge" sx={{ textAlign: "center", mt: 2 }}>
          {t("checkYourLink", {
            contactMethod: emailOrPhone?.includes("@")
              ? t("email").toLowerCase()
              : t("phone"),
          })}
        </Text>
      </Stack>
    </Box>
  );
};

export default RegistrationVerificationPage;

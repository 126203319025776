import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import Divider from "components/Divider";
import Stack from "components/Stack";
import Text from "components/Text";

import ParsedPrimaryContactForm from "entities/ParsedPrimaryContact/components/Form";
import {
  parsedPrimaryContactUpdate,
  useDetailDocumentData,
} from "entities/Documents/sdk";

import { reverse } from "utils/urls";

import { URLS } from "config/urls";
import { extractErrorMessage } from "utils/error";

const PrimaryContact = () => {
  const { documentId: documentIdParam } = useParams<{ documentId: string }>();
  const documentId = parseInt(String(documentIdParam));
  const navigate = useNavigate();

  const { data } = useDetailDocumentData(documentId);
  const familyData = data?.family_data;

  const { t } = useTranslation();

  const handleSave = (values: any) => {
    if (!data) {
      return Promise.reject();
    }

    return parsedPrimaryContactUpdate({
      familyId: data.family_data.id,
      data: values,
    })
      .then(() => {
        navigate(
          reverse(URLS.FAMILY_SPECIALIST_DOCUMENTS, {
            documentId: String(documentId),
            stepname: "parents",
          })
        );
      })
      .catch((error) => {
        const errorMessage = extractErrorMessage(error);
        toast.error(errorMessage);
      });
  };

  if (!familyData) {
    return null;
  }

  return (
    <>
      <Stack sx={{ mb: "30px" }} gap="15px">
        <Text variant="titleMedium">{t("primaryContact")}</Text>
        <Divider />
      </Stack>
      <ParsedPrimaryContactForm
        initialValues={{
          creator: familyData.creator,
          street_address: familyData.street_address,
          unit_address: familyData.unit_address,
          city: familyData.city,
          zip_code: familyData.zip_code,
        }}
        onSave={handleSave}
      />
    </>
  );
};

export default PrimaryContact;

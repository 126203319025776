import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";

import BasicButton from "components/BasicButton";
import Text from "components/Text";
import Stack from "components/Stack";
import TextField from "components/TextField";
import FormHelperText from "components/FormHelperText";
import useMediaQuery from "components/useMediaQuery";

import { URLS } from "config/urls";
import { getHelpAddNote } from "entities/FamilyMember/sdk";
import { useMyFamily } from "entities/Family/sdk";

const GetHelpPage = () => {
  const { t } = useTranslation();
  const { data: family } = useMyFamily();
  const familyId = family?.id;
  const {
    control,
    watch,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      needHelp: "",
    },
  });
  const navigate = useNavigate();
  const matches = useMediaQuery("(min-width:600px)");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const watchData = watch("needHelp");
  if (!familyId) {
    return null;
  }
  const onSubmit = () => {
    if (!watchData) {
      setError("needHelp", {
        type: "manual",
        message: t("fieldRequired"),
      });
      return;
    }
    setIsSubmitting(true);
    const data = {
      content: watchData,
    };
    getHelpAddNote(familyId, data)
      .then(() => {
        setIsSubmitting(false);
        navigate(URLS.THANK_YOU);
      })
      .catch(() => {
        const errorMessage = t("errorSavingData");
        toast.error(errorMessage);
        setIsSubmitting(false);
      });
  };

  return (
    <Stack
      flexDirection={"column"}
      justifyContent={"space-between"}
      alignItems={"center"}
      sx={{
        minHeight: `calc(100vh - 140px)`,
      }}
    >
      <Stack
        gap="20px"
        alignItems={"flex-start"}
        sx={{ width: matches ? "50%" : "100%" }}
      >
        <Text variant="titleMedium" sx={{ mt: 2, mb: 1 }}>
          {t("describeNeeds")}
        </Text>
        <Controller
          name="needHelp"
          control={control}
          render={({ field }) => (
            <>
              <TextField
                {...field}
                fullWidth
                multiline
                minRows={3}
                variant="outlined"
                label={t("describeWhatYouNeddHelpWIth")}
                sx={{ mb: 0 }}
                error={!!errors.needHelp}
              />
              {!!errors.needHelp && (
                <FormHelperText error>{errors.needHelp.message}</FormHelperText>
              )}
            </>
          )}
        />
      </Stack>
      <Stack
        gap="20px"
        alignItems="center"
        sx={{
          textAlign: "right",
          mt: "30px",
        }}
      >
        <BasicButton
          label={t("sendToChildCareCircuit")}
          onClick={handleSubmit(onSubmit)}
          isDisabled={isSubmitting}
        />
      </Stack>
    </Stack>
  );
};

export default GetHelpPage;

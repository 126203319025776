import { IFilters } from "components/FilterSearchRow";
import { useSearchParams } from "react-router-dom";

export const useFilters = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const changeFilters = (filters: IFilters) => {
    const existingParams = Object.fromEntries(searchParams.entries());

    const merged = {
      ...existingParams,
      ...filters,
      offset: "0",
    };

    const newParams = new URLSearchParams(
      Object.entries(merged).map(([key, value]) => [key, String(value)])
    );

    setSearchParams(newParams);
  };

  const changePagination = ({
    offset,
    limit,
    rowsPerPage,
  }: {
    offset: number;
    limit: number;
    rowsPerPage: number;
  }) => {
    const existingParams = Object.fromEntries(searchParams.entries());

    const merged = {
      ...existingParams,
      offset: String(offset),
      limit: String(limit),
      rowsPerPage: String(rowsPerPage),
    };

    const newParams = new URLSearchParams(
      Object.entries(merged).map(([key, value]) => [key, String(value)])
    );

    setSearchParams(newParams);
  };

  return {
    changeFilters,
    changePagination,
    filters: {
      action_needed: searchParams.get("action_needed") === "true",
      assigned_to_me: searchParams.get("assigned_to_me") === "true",
      search: searchParams.get("search") ?? "",
      auth_type: searchParams.get("auth_type") ?? "",
    },
    pagination: {
      offset: parseInt(searchParams.get("offset") ?? "0"),
      limit: parseInt(searchParams.get("limit") ?? "10"),
      rowsPerPage: parseInt(searchParams.get("rowsPerPage") ?? "10"),
    },
  };
};

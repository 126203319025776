import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import useMediaQuery from "components/useMediaQuery";
import Box from "components/Box";
import Stack from "components/Stack";
import BasicButton from "components/BasicButton";
import Text from "components/Text";

import { URLS } from "config/urls";

import { AssignedIcon, DocumentIcon } from "icons";
import { colors } from "theme";

import { voucherStatusUpdate } from "entities/FamilyMember/sdk";
import { useMyFamily } from "entities/Family/sdk";

const CheckNeeds = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const summary = t("lookingForChildcareHelp");
  const matches = useMediaQuery("(min-width:600px)");

  const { data: family } = useMyFamily();

  if (!family) return null;

  const handleNextClick = () => {
    voucherStatusUpdate(family?.vouchers[0].id, {
      type: "WAITLIST",
    }).then(() => {
      navigate(URLS.FAMILY_INFORMATION);
    });
  };

  const handleUpdateInfo = () => {
    navigate(URLS.CHILD_CARE_NEEDS_UPDATE_INFORMATION);
  };

  return (
    <>
      <Box sx={{ width: matches ? "50%" : "auto", alignSelf: "center" }}>
        <Stack alignItems={"center"}>
          <Text variant="titleLarge" sx={{ mt: 2, mb: 2 }}>
            {t("checkSummary", { summary })}
          </Text>
          <Stack direction="row" alignItems="center" gap="15px">
            <AssignedIcon width="34px" />
            <Text variant="titleMedium" sx={{ mt: 2, mb: 2 }}>
              {t("assignedWorker")}
            </Text>
          </Stack>
          <Stack direction="row" alignItems="center" gap="15px">
            <DocumentIcon width="34px" />
            <Text variant="titleMedium" sx={{ mt: 2, mb: 2 }}>
              {t("collectingAdditionalInfo")}
            </Text>
          </Stack>
        </Stack>
      </Box>
      <Stack
        sx={{
          borderTop: matches ? "1px solid" : "none",
          borderColor: colors.outline,
        }}
      >
        <Stack
          gap="20px"
          alignItems="center"
          sx={{
            textAlign: "right",
            mt: "30px",
          }}
        >
          <BasicButton
            label={t("helpWithSomethingElse")}
            backgroundColor={colors.secondaryContainer}
            color={colors.onSecondaryContainer}
            onHoverBackground={colors.onHoverSecondaryContainer}
            onClick={handleUpdateInfo}
          />
          <BasicButton label={t("next")} onClick={handleNextClick} />
        </Stack>
      </Stack>
    </>
  );
};

export default CheckNeeds;

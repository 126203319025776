import { useState } from "react";

import { useNavigate } from "react-router-dom";

import dayjs from "dayjs";

import Stack from "components/Stack";
import Text from "components/Text";
import BasicButton from "components/BasicButton";
import TablePagination from "components/TablePagination";
import Box from "components/Box";

import { useTranslation } from "react-i18next";

import { colors } from "theme";

import { ChatIcon, MailOutlinedIcon } from "icons";

import SendMessageDialog from "entities/Messaging/SendMessageDialog";
import { useMessagesList } from "entities/Messaging/sdk";

import { reverse } from "utils/urls";
import { URLS } from "config/urls";

import "./CustomStyles.css";

const MessagesListPage = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { data: messagesData, mutate } = useMessagesList(
    rowsPerPage,
    page * rowsPerPage
  );
  const navigate = useNavigate();

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    mutate();
  };

  const openFamilyDetail = (id: number) => {
    if (id) {
      navigate(
        reverse(URLS.FAMILY_SPECIALIST_FAMILY_DETAIL, {
          id: id.toString(),
        })
      );
    }
  };

  return (
    <Stack
      justifyContent="flex-start"
      gap="20px"
      sx={{
        minHeight: "calc(100vh - 60px)",
        backgroundColor: colors.surface,
        padding: "30px",
      }}
    >
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        sx={{ padding: "16px" }}
        justifyContent="space-between"
        gap="30px"
      >
        <Text variant="titleLarge" sx={{ mt: 2, mb: 1, flex: 1 }}>
          {t("messages")}
        </Text>
        <Stack direction="row" gap="15px" flex={2} justifyContent={"flex-end"}>
          <BasicButton
            label={t("newMessage")}
            onClick={handleOpen}
            width="160px"
          />
        </Stack>
      </Stack>

      <Stack
        direction="row"
        gap="15px"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          borderTop: "1px solid",
          borderColor: colors.outline,
          pt: "20px",
          cursor: "pointer",
        }}
      >
        <Stack
          direction="row"
          gap="20px"
          alignItems="center"
          justifyContent="space-between"
          flex={3}
        >
          <Box sx={{ width: "24px", height: "24px" }}></Box>
          <Text
            variant="labelLarge"
            color={colors.onSurfaceVariant}
            fontWeight={"600"}
            sx={{ flex: 1 }}
          >
            {t("from")}
          </Text>

          <Text
            variant="labelLarge"
            color={colors.onSurfaceVariant}
            fontWeight={"600"}
            sx={{ flex: 1 }}
          >
            {t("familyName")}
          </Text>
          <Text
            variant="labelLarge"
            color={colors.onSurfaceVariant}
            fontWeight={"600"}
            sx={{ flex: 1 }}
          >
            {t("subject")}
          </Text>
          <Text
            variant="labelLarge"
            color={colors.onSurfaceVariant}
            fontWeight={"600"}
            sx={{ flex: 1 }}
          >
            {t("message")}
          </Text>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          gap="15px"
          justifyContent="flex-end"
          flex={1}
        >
          <Text
            variant="labelLarge"
            color={colors.onSurfaceVariant}
            sx={{ flex: 1, textAlign: "end", paddingRight: "20px" }}
            fontWeight={"600"}
          >
            {t("date")}
          </Text>
        </Stack>
      </Stack>
      {messagesData?.results.map((message) => (
        <Stack
          direction="row"
          gap="15px"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            borderTop: "1px solid",
            borderColor: colors.outline,
            pt: "20px",
            cursor: "pointer",
            height: "77px",
          }}
          onClick={() => openFamilyDetail(message.family.id)}
        >
          <Stack
            direction="row"
            gap="20px"
            alignItems="center"
            justifyContent="space-between"
            flex={3}
          >
            {message.communication_type === "EMAIL" ? (
              <MailOutlinedIcon />
            ) : (
              <ChatIcon />
            )}
            <Text variant="titleMedium" fontWeight="bold" sx={{ flex: 1 }}>
              {message.sent_from.first_name} {message.sent_from.last_name}
            </Text>
            <Text variant="titleMedium" sx={{ flex: 1 }}>
              {message.family.creator.first_name}{" "}
              {message.family.creator.last_name}
            </Text>
            <Text variant="titleMedium" sx={{ flex: 1 }}>
              {message.subject}
            </Text>
            {message.communication_type === "EMAIL" ? (
              <Stack
                flexDirection={"row"}
                alignItems={"flex-start"}
                sx={{
                  flex: 1,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "100px",
                  maxHeight: "51px",
                }}
                dangerouslySetInnerHTML={{ __html: message.body }}
              />
            ) : (
              <Text
                variant="titleMedium"
                sx={{
                  flex: 1,
                  my: "16px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "100px",
                }}
              >
                {message.body}
              </Text>
            )}
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            gap="15px"
            justifyContent="flex-end"
            flex={1}
          >
            <Text fontStyle="italic">
              {dayjs(message.created_at).format("MM/DD/YYYY - hh:mmA")}
            </Text>
          </Stack>
        </Stack>
      ))}
      <TablePagination
        component="div"
        count={messagesData?.count || 0}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[10, 25, 50, 100]}
        sx={{
          ".MuiTablePagination-toolbar": {
            justifyContent: "space-between",
            padding: "0px 24px",
          },
          ".MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows":
            {
              margin: 0,
            },
          ".MuiTablePagination-actions": {
            marginLeft: 0,
          },
          "& .MuiInputBase-input": {
            borderRadius: "4px",
            backgroundColor: colors.surfaceContainerLow,
            border: "1px solid #ced4da",
            padding: "10px 26px 10px 12px",
          },
          "& .MuiTablePagination-input": {
            position: "absolute",
            left: "135px",
          },
          "& .MuiTablePagination-spacer": {
            display: "none",
          },
        }}
      />
      <SendMessageDialog open={open} onClose={handleClose} />
    </Stack>
  );
};

export default MessagesListPage;

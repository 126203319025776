import { DeepPartial } from "utils/common";
import { BASE_URL, post, useFetch, get } from "utils/sdk";
import { PreferredContactMethod } from "entities/FamilyEdit/sdk";
import { AuthorizationStatus } from "entities/Authorization/sdk";
import { IDocument } from "entities/Documents/sdk";
import { IFamilyMember } from "entities/FamilySpecialist/sdk";
import { employmentStatus } from "entities/FamilyMember/sdk";

export interface IFamilyVoucher {
  id: number;
  type:
    | "UNKNOWN"
    | "WAITLIST"
    | "OTHER"
    | "DTA_REFERRAL"
    | "DCF_REFERRAL"
    | "HOMELESS_REFERRAL"
    | "INCOME_ELIGIBLE";
  status: AuthorizationStatus;
  created_at: string;
  updated_at: string;
  children: Array<{
    id: number;
    first_name: string;
    last_name: string;
  }>;
  referral_source: string;
  family_has_experienced_domestic_violence: boolean;
  family_is_experiencing_homelessness: boolean;
  parent_has_a_disability: boolean;
  parent_is_a_member_of_the_us_military: boolean;
  parent_is_an_early_educator: boolean;
  parent_is_receiving_treatment_for_substance_abuse: boolean;
  teen_parent: boolean;
  child_only_needs_coverage_during_school_closures: boolean;
  is_on_waitlist: boolean;
  referral_date: string;
  child_care_status: string;
  shelter: string;
  shelter_contact: string;
  shelter_contact_email: string;
  shelter_phone: string;
  dta_referral_number: string;
  ccrr: string;
  dta_program: string;
  service_need: string;
  dcf_case_number: string;
  dcf_authorization_number: string;
  dcf_region: string;
  issuing_location: string;
  tafdc_closing_date: string;
  dta_referral_type: string;
  placements: Array<{
    id: number;
    voucher: {
      id: number;
      created_at: string;
      updated_at: string;
      type:
        | "UNKNOWN"
        | "WAITLIST"
        | "OTHER"
        | "DTA_REFERRAL"
        | "DCF_REFERRAL"
        | "HOMELESS_REFERRAL"
        | "INCOME_ELIGIBLE";
    };
    child_care_need: "FULL_TIME" | "PART_TIME";
    provider_name: string;
    child_care_duration: string;
    child_care_transportation_needs:
      | "NO_NEED"
      | "HOME_TO_PROVIDER"
      | "PROVIDER_TO_HOME"
      | "HOME_TO_PROVIDER_AND_PROVIDER_TO_HOME";
    provider_program: string;
    updated_at: string;
    children: {
      id: number;
      first_name: string;
      last_name: string;
    }[];
  }>;
  dependents: Array<{
    id: number;
    first_name: string;
    last_name: string;
    date_of_birth: string;
  }>;
  household_size: number;
  total_annual_income: number;
  annual_income_status: string;
}

export interface IParentToIncomeEligibles {
  voucher: {
    id: number;
  };
  is_military_retired: boolean;
  is_in_active_military_duty: boolean;
  has_wages_from_employment: boolean;
  total_monthly_wages: number;
  total_hours_per_week: number;
  has_self_employment_income: boolean;
  is_seeking_work_or_education: boolean;
  hours_per_day_seeking_work_or_training: number;
  is_attending_school_or_training: boolean;
  total_enrolled_course_credits: number;
  total_hours_a_week_in_school_or_training: number;
  has_other_income: boolean;
  has_assets_over_1m: boolean;
  has_rental_income: boolean;
  total_rental_income: number;
  is_resident_at_rental_property: boolean;
  has_lottery_earnings: boolean;
  total_lottery_earnings: number;
  has_alimony: boolean;
  total_alimony: number;
  has_workers_compensation: boolean;
  total_workers_compensation: number;
  has_unemployment_benefits: boolean;
  total_unemployment_benefits: number;
  has_retirement_income: boolean;
  total_retirement_income: number;
  total_monthly_income: number;
  month_year_reported: string;
  gross_total_receipts_or_sales_in_month: number;
  advertising: number;
  automobile_expenses: number;
  commissions_and_fees: number;
  cost_of_goods_sold: number;
  contact_labour: number;
  employee_benefit_programs: number;
  insurance: number;
  interest_paid_on_mortgage_owed_banks: number;
  other_interest_payment: number;
  legal_and_professional_services: number;
  office_expenses: number;
  pension_or_profit_sharing_plan: number;
  repair_and_maintenance: number;
  rent_for_leased_vehicle_machinery_equipment: number;
  rent_for_other_business_property: number;
  supplies: number;
  taxes_and_licenses: number;
  transportation: number;
  utilities: number;
  wages_and_salaries_for_employees: number;
  total_expenses: number;
  net_income: number;
}

export interface IFamily {
  id: number;
  creator: {
    id: number;
    first_name: string;
    last_name: string;
    preferred_language: "english" | "spanish" | "capeverdean" | "haitian";
    preferred_contact_method: PreferredContactMethod;
    phone_contact_methods: Array<{
      id: number;
      phone_number: string;
      status: "ACTIVE" | "INACTIVE" | "NO_CONCEPT";
    }>;
    email_contact_methods: Array<{
      id: number;
      email: string;
      status: "ACTIVE" | "INACTIVE" | "NO_CONCEPT";
    }>;
    child_care_need_summary: string;
    date_of_birth: string;
    employment_status: employmentStatus;
    employer_name: string;
    other_status_description: string;
    gender: "MEN" | "WOMEN";
  };
  family_members: IFamilyMember[];
  children: Array<{
    id: number;
    first_name: string;
    last_name: string;
    gender: "MEN" | "WOMEN";
    preferred_language: "english" | "spanish" | "capeverdean" | "haitian";
    date_of_birth: string;
    relationship: "naturalChild" | "fosterChild" | "guardianChild";
    date_when_child_care_is_needed: string;
    child_has_a_disability: boolean;
    child_is_in_head_start_and_needs_more_hours_of_coverage: boolean;
    age: number | null;
  }>;
  dependents: Array<{
    id: number;
    first_name: string;
    last_name: string;
    date_of_birth: string;
  }>;
  vouchers: Array<IFamilyVoucher>;
  missing_fields: Array<{
    has_family_member_missing_data: boolean;
    voucher_id: number;
    missing_fields: {
      family_missing_fields: string[];
      parent_missing_fields: Array<{
        id: number;
        missing_fields: string[];
      }>;
      child_missing_fields: Array<{
        id: number;
        missing_fields: string[];
      }>;
      voucher_missing_fields: string[];
      global_errors: string[];
      missing_documents: string[];
      placement_missing_fields: Array<{
        id: number;
        missing_fields: string[];
      }>;
      child_with_missing_placement: Array<{
        child_id: number;
      }>;
    };
  }>;
  street_address: string;
  unit_address: string;
  city: string;
  zip_code: string;
  documents: IDocument[];
}

export const familyUpdate = ({ data }: { data: DeepPartial<IFamily> }) =>
  post(`${BASE_URL}/api/family-members/family/update/`, data);

export const useMyFamily = () =>
  useFetch<IFamily>("/api/family-members/family/detail/");

export const getMyFamily = () => {
  return get<IFamily>(`${BASE_URL}/api/family-members/family/detail/`);
};

export const incomeEligibleSummaryNoteCreate = ({
  data,
}: {
  data: { child_care_need_summary: string };
}) =>
  post(
    `${BASE_URL}/api/family-members/income-eligible/summary-note/create/ `,
    data
  );

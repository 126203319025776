import { useState } from "react";
import { useTranslation } from "react-i18next";

import Text from "components/Text";
import Box from "components/Box";
import Paper from "components/Paper";
import IconButton from "components/IconButton";
import InputBase from "components/InputBase";
import FormControlLabel from "components/FormControlLabel";
import Checkbox from "components/Checkbox";
import StyledSelect from "components/StyledSelect";

import { MagnifierIcon } from "icons";
import { colors } from "theme";
import { useTypeOptions } from "entities/FamilyEdit/sdk";

export interface IFilters {
  action_needed: boolean;
  assigned_to_me: boolean;
  search: string;
  auth_type: string;
}

interface FilterSearchRowProps {
  onChange: (filters: IFilters) => void;
  filters: IFilters;
}

const FilterSearchRow = ({ onChange, filters }: FilterSearchRowProps) => {
  const { t } = useTranslation();
  const authTypeOptions = useTypeOptions();
  const [search, setSearch] = useState(filters.search);

  const handleActionNeededSelect = () => {
    onChange({ ...filters, action_needed: !filters.action_needed });
  };

  const toggleAssignedToMe = () => {
    onChange({ ...filters, assigned_to_me: !filters.assigned_to_me });
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const ENTER_KEY_CODE = 13;
    if (event.which === ENTER_KEY_CODE) {
      onChange({ ...filters, search });
    }
  };

  const handleSearchClick = () => {
    onChange({ ...filters, search });
  };

  const handleAuthTypeChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const newType = event.target.value as string;
    onChange({ ...filters, auth_type: newType });
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
      <FormControlLabel
        control={
          <Checkbox
            checked={filters.assigned_to_me}
            onChange={toggleAssignedToMe}
            inputProps={{ "aria-label": "Assigned to me" }}
            sx={{
              color: colors.primary,
              "&.Mui-checked": {
                color: colors.primary,
              },
            }}
          />
        }
        label={<Text variant="body1">{t("assignedToMe")}</Text>}
        sx={{ marginRight: 2 }}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={filters.action_needed}
            onChange={handleActionNeededSelect}
            inputProps={{ "aria-label": "Action Needed" }}
            sx={{
              color: colors.primary,
              "&.Mui-checked": {
                color: colors.primary,
              },
            }}
          />
        }
        label={<Text variant="body1">{t("actionNeeded")}</Text>}
        sx={{ marginRight: 2 }}
      />

      <Box sx={{ minWidth: 220 }}>
        <StyledSelect
          label="Voucher Type"
          value={filters.auth_type}
          onChange={handleAuthTypeChange}
          options={[{ value: "", label: "All" }].concat(authTypeOptions)}
          fullWidth
        />
      </Box>

      <Paper
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "4px 16px",
          borderRadius: "25px",
          backgroundColor: colors.surfaceContainerHigh,
          boxShadow: "none",
          flex: 1,
          ml: "40px",
          height: "56px",
        }}
      >
        <InputBase
          placeholder={t("search")}
          value={search}
          onChange={handleSearchChange}
          onKeyPress={handleKeyPress}
          inputProps={{ "aria-label": "search" }}
          sx={{ flex: 1, marginLeft: 1 }}
        />
        <IconButton onClick={handleSearchClick} aria-label="search">
          <MagnifierIcon />
        </IconButton>
      </Paper>
    </Box>
  );
};

export default FilterSearchRow;

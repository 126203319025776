import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import _ from "lodash";

import AddButton from "components/AddButton";
import FilterSearchRow, { IFilters } from "components/FilterSearchRow";
import Stack from "components/Stack";
import Text from "components/Text";
import Link from "components/Link";
import Skeleton from "components/Skeleton";

import { colors } from "theme";
import { URLS } from "config/urls";
import { reverse } from "utils/urls";
import { formatToCamelCase } from "utils/format";

import useReadableStatus, {
  CompletedStatus,
  useFamily,
} from "entities/FamilySpecialist/sdk";
import TablePagination from "components/TablePagination";
import { useFilters } from "./hooks";

const FamilyListPage = () => {
  const { t } = useTranslation();
  const { getReadableCompletedStatus } = useReadableStatus();

  const navigate = useNavigate();

  const { changeFilters, changePagination, filters, pagination } = useFilters();

  const { data: familiesData, isLoading } = useFamily({
    ...filters,
    ...pagination,
  });
  const [families, setFamilies] = useState<any[]>([]);

  useEffect(() => {
    if (familiesData?.results) {
      setFamilies(familiesData.results);
    }
  }, [familiesData]);

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    changePagination({
      ...pagination,
      offset: newPage * pagination.rowsPerPage,
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newRowsPerPage = parseInt(event.target.value, 10);

    changePagination({
      ...pagination,
      offset: 0,
      rowsPerPage: newRowsPerPage,
      limit: newRowsPerPage,
    });
  };

  const handleFilterChange = (filters: IFilters) => {
    changeFilters(filters);
  };

  const openFamilyDetail = (id: number) => {
    if (id) {
      navigate(
        reverse(URLS.FAMILY_SPECIALIST_FAMILY_DETAIL, {
          id: id.toString(),
        })
      );
    }
  };

  const handleButtonClick = (
    event: React.MouseEvent<HTMLButtonElement> | undefined
  ) => {
    if (event) {
      event.stopPropagation();
      window.open(
        "https://earlyedncareop.eec.state.ma.us/KinderWait/ActivityPages/ApplicationPages/AddNewApplication.aspx",
        "_blank"
      );
    }
  };

  return (
    <Stack
      justifyContent="flex-start"
      gap="20px"
      sx={{
        minHeight: "calc(100vh - 60px)",
        backgroundColor: colors.surface,
        padding: "30px",
      }}
    >
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        sx={{ padding: "16px" }}
        justifyContent="space-between"
        gap="30px"
      >
        <Text variant="titleLarge" sx={{ mt: 2, mb: 1 }}>
          {t("families")}
        </Text>
        <FilterSearchRow onChange={handleFilterChange} filters={filters} />
      </Stack>
      {families.map((family) => (
        <Stack
          direction="row"
          gap="15px"
          justifyContent="space-between"
          alignItems="center"
          key={family.id}
          sx={{
            borderTop: "1px solid",
            borderColor: colors.outline,
            py: "10px",
            cursor: "pointer",
            position: "relative",
          }}
          onClick={() => openFamilyDetail(family.id)}
        >
          {isLoading ? (
            <Skeleton
              variant="rectangular"
              width="100%"
              height={60}
              animation="wave"
              sx={{ borderRadius: "4px", mt: 2 }}
            />
          ) : (
            <>
              <Stack
                direction="row"
                gap="20px"
                alignItems="center"
                justifyContent="space-between"
                flex={1}
              >
                <Text
                  variant="titleMedium"
                  fontWeight="bold"
                  sx={{ mt: 2, mb: 1, flex: 1 }}
                >
                  {family.preferred_contact.first_name}{" "}
                  {family.preferred_contact.last_name}
                </Text>

                <Text variant="titleMedium" sx={{ mt: 2, mb: 1, flex: 1 }}>
                  {getReadableCompletedStatus(
                    family.vouchers[family.vouchers.length - 1]
                      ?.status as CompletedStatus
                  )}
                </Text>
                <Text variant="titleMedium" sx={{ mt: 2, mb: 1, flex: 1 }}>
                  {family.vouchers &&
                  family.vouchers[family.vouchers.length - 1]
                    ? t(
                        formatToCamelCase(
                          family.vouchers[family.vouchers.length - 1].type
                        )
                      )
                    : t("unknown")}
                </Text>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                gap="15px"
                justifyContent="flex-end"
                flex={1}
              >
                {family.vouchers[family.vouchers.length - 1].type ===
                  "WAITLIST" &&
                  family.vouchers[family.vouchers.length - 1].status !==
                    "ISSUED" &&
                  family.vouchers[family.vouchers.length - 1].status !==
                    "ON_WAITLIST" && (
                    <Stack sx={{ marginTop: "16px", marginBottom: "8px" }}>
                      <AddButton
                        label={t("addToWaitlist")}
                        onClick={handleButtonClick}
                      />
                    </Stack>
                  )}
                {[
                  "DCF_REFERRAL",
                  "DTA_REFERRAL",
                  "HOMELESS_REFERRAL",
                  "INCOME_ELIGIBLE",
                ].includes(
                  family.vouchers[family.vouchers.length - 1]?.type
                ) && (
                  <Stack sx={{ marginTop: "16px", marginBottom: "8px" }}>
                    <Link
                      to="https://ccfa.eec.state.ma.us/"
                      external
                      target="_blank"
                    >
                      <AddButton label={t("addToCCFA")} />
                    </Link>
                  </Stack>
                )}
                <Text fontStyle="italic" sx={{ mt: 2, mb: 1 }}>
                  {t("lastActivity")}: {family.preferred_contact.updated_at}
                </Text>
              </Stack>
            </>
          )}
        </Stack>
      ))}
      <TablePagination
        component="div"
        count={familiesData?.count || 0}
        page={pagination.offset / pagination.rowsPerPage}
        onPageChange={handleChangePage}
        rowsPerPage={pagination.rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[10, 25, 50, 100]}
        sx={{
          ".MuiTablePagination-toolbar": {
            justifyContent: "space-between",
            padding: "0px 24px",
          },
          ".MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows":
            {
              margin: 0,
            },
          ".MuiTablePagination-actions": {
            marginLeft: 0,
          },
          "& .MuiInputBase-input": {
            borderRadius: "4px",
            backgroundColor: colors.surfaceContainerLow,
            border: "1px solid #ced4da",
            padding: "10px 26px 10px 12px",
          },
          "& .MuiTablePagination-input": {
            position: "absolute",
            left: "135px",
          },
          "& .MuiTablePagination-spacer": {
            display: "none",
          },
        }}
      />
    </Stack>
  );
};

export default FamilyListPage;

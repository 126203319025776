import Dialog, { DialogTitle, DialogContent } from "components/Dialog";
import IconButton from "components/IconButton";
import BasicButton from "components/BasicButton";
import Stack from "components/Stack";
import useMediaQuery from "components/useMediaQuery";

import { CloseIcon } from "icons";

import { colors } from "theme";

import { IFamilyDetail } from "entities/FamilySpecialist/sdk";

import { DataProvider } from "./DataContext";

interface EditPanelProps {
  open: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
  data?: IFamilyDetail;
  onSaveClick?: () => void;
  isSubmitDisabled?: boolean;
}

const EditPanel = ({
  open,
  onClose,
  title,
  children,
  data,
  onSaveClick,
  isSubmitDisabled,
}: EditPanelProps) => {
  const matches = useMediaQuery("(min-width:600px)");

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          position: "fixed",
          top: 0,
          right: 0,
          m: 0,
          height: "100%",
          width: "40%",
          borderRadius: 0,
          maxHeight: "100%",
          backgroundColor: colors.surfaceContainer,
        },
      }}
    >
      <DialogTitle>
        {title}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon style={{ fill: colors.onSurfaceVariant }} />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <DataProvider value={data || null}>
          {!onSaveClick && children}

          {onSaveClick && (
            <Stack sx={{ height: "100%" }} justifyContent="space-between">
              <Stack>{children}</Stack>

              <Stack
                sx={{
                  borderTop: matches ? "1px solid" : "none",
                  borderColor: colors.outline,
                }}
              >
                <Stack
                  gap="20px"
                  alignItems="center"
                  sx={{
                    textAlign: "right",
                    mt: "30px",
                    mb: "20px",
                  }}
                >
                  <BasicButton
                    label="Save"
                    onClick={onSaveClick}
                    isDisabled={isSubmitDisabled}
                  />
                </Stack>
              </Stack>
            </Stack>
          )}
        </DataProvider>
      </DialogContent>
    </Dialog>
  );
};

export default EditPanel;

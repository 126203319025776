import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useHouseholdSizeFamilySpecialist } from "entities/FamilySpecialist/sdk";

import TextField from "components/TextField";
import Checkbox from "components/Checkbox";
import Divider from "components/Divider";
import FormControlLabel from "components/FormControlLabel";
import Radio, { RadioGroup } from "components/Radio";
import Text from "components/Text";
import Stack from "components/Stack";
import FormControl from "components/FormControl";

import { colors } from "theme";

interface IProps {
  familyId: number;
  showCreatedAt?: boolean;
}

const Waitlist = ({ familyId, showCreatedAt }: IProps) => {
  const { t } = useTranslation();
  const { control, watch, setValue } = useFormContext();

  const householdSize = Number(watch("household_size"));
  const [validatedSize, setValidatedSize] = useState<number | null>(null);

  const { data: householdData } = useHouseholdSizeFamilySpecialist(
    familyId,
    validatedSize!
  );

  const [annualIncomePerYear, setAnnualIncomePerYear] = useState<number | null>(
    householdData?.annual_income_per_year || null
  );

  useEffect(() => {
    if (householdSize >= 2 && householdSize <= 12) {
      setValidatedSize(householdSize);

      if (householdData?.annual_income_per_year) {
        const newIncome = householdData.annual_income_per_year;
        setAnnualIncomePerYear(newIncome);
        setValue("total_annual_income", newIncome);
      } else {
        setAnnualIncomePerYear(null);
        setValue("total_annual_income", null);
      }
    } else {
      setValidatedSize(null);
      setValue("household_size", null);
      setValue("annual_income_status", undefined);
      setValue("total_annual_income", null);
      setAnnualIncomePerYear(null);
    }
  }, [householdSize, householdData, setValue]);

  return (
    <>
      <Controller
        name="household_size"
        control={control}
        rules={{
          min: { value: 2, message: t("numberRangeError") },
          max: { value: 12, message: t("numberRangeError") },
        }}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            type="number"
            label={t("householdSize")}
            fullWidth
            margin="normal"
            inputProps={{ min: "2", max: "12", step: "1" }}
            error={!!fieldState.error}
            helperText={fieldState.error?.message}
          />
        )}
      />
      {annualIncomePerYear && (
        <Stack>
          <Text
            variant="titleMedium"
            sx={{
              mt: (theme) => theme.spacing(2),
              alignSelf: "flex-start",
            }}
          >
            {t("totalHouseholdIncome")}
          </Text>
          <FormControl
            component="fieldset"
            margin="normal"
            sx={{ width: "100%" }}
          >
            <Controller
              name="annual_income_status"
              control={control}
              render={({ field }) => (
                <RadioGroup
                  {...field}
                  row
                  aria-label="employment status"
                  sx={{ flexDirection: "column" }}
                >
                  <FormControlLabel
                    value="MORE_THAN"
                    control={<Radio />}
                    label={t("moreThan", {
                      yearAmount: annualIncomePerYear.toLocaleString("en-US"),
                    })}
                    sx={{
                      borderBottom: "1px solid",
                      borderColor: colors.outlineVariant,
                      marginRight: 0,
                      padding: "10px 0",
                    }}
                  />
                  <FormControlLabel
                    value="LESS_THAN"
                    control={<Radio />}
                    label={t("lessThan", {
                      yearAmount: annualIncomePerYear.toLocaleString("en-US"),
                    })}
                    sx={{
                      borderBottom: "1px solid",
                      borderColor: colors.outlineVariant,
                      marginRight: 0,
                      padding: "10px 0",
                    }}
                  />
                  <FormControlLabel
                    value="NOT_WORKING"
                    control={<Radio />}
                    label={t("notWorking")}
                    sx={{
                      borderBottom: "1px solid",
                      borderColor: colors.outlineVariant,
                      marginRight: 0,
                      padding: "10px 0",
                    }}
                  />
                </RadioGroup>
              )}
            />
          </FormControl>
        </Stack>
      )}
      {showCreatedAt && (
        <Controller
          name="created_at"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("createdAt")}
              fullWidth
              margin="normal"
              InputProps={{
                readOnly: true,
              }}
              disabled
            />
          )}
        />
      )}
      <Controller
        name="is_on_waitlist"
        control={control}
        render={({ field }) => (
          <FormControlLabel
            control={<Checkbox {...field} checked={field.value} />}
            label={t("isOnWaitlist")}
          />
        )}
      />
      <Controller
        name="family_has_experienced_domestic_violence"
        control={control}
        render={({ field }) => (
          <FormControlLabel
            control={<Checkbox {...field} checked={field.value} />}
            label={t("domesticViolence")}
          />
        )}
      />
      <Divider sx={{ my: "10px" }} />
      <Controller
        name="family_is_experiencing_homelessness"
        control={control}
        render={({ field }) => (
          <FormControlLabel
            control={<Checkbox {...field} checked={field.value} />}
            label={t("homelessness")}
          />
        )}
      />
      <Divider sx={{ my: "10px" }} />
      <Controller
        name="parent_has_a_disability"
        control={control}
        render={({ field }) => (
          <FormControlLabel
            control={<Checkbox {...field} checked={field.value} />}
            label={t("parentDisability")}
          />
        )}
      />
      <Divider sx={{ my: "10px" }} />
      <Controller
        name="parent_is_a_member_of_the_us_military"
        control={control}
        render={({ field }) => (
          <FormControlLabel
            control={<Checkbox {...field} checked={field.value} />}
            label={t("militaryMember")}
          />
        )}
      />
      <Divider sx={{ my: "10px" }} />
      <Controller
        name="parent_is_an_early_educator"
        control={control}
        render={({ field }) => (
          <FormControlLabel
            control={<Checkbox {...field} checked={field.value} />}
            label={t("earlyEducator")}
          />
        )}
      />
      <Divider sx={{ my: "10px" }} />
      <Controller
        name="parent_is_receiving_treatment_for_substance_abuse"
        control={control}
        render={({ field }) => (
          <FormControlLabel
            control={<Checkbox {...field} checked={field.value} />}
            label={t("substanceAbuseTreatment")}
          />
        )}
      />
      <Divider sx={{ my: "10px" }} />
      <Controller
        name="teen_parent"
        control={control}
        render={({ field }) => (
          <FormControlLabel
            control={<Checkbox {...field} checked={field.value} />}
            label={t("teenParent")}
          />
        )}
      />
      <Divider sx={{ my: "10px" }} />
      <Controller
        name="child_only_needs_coverage_during_school_closures"
        control={control}
        render={({ field }) => (
          <FormControlLabel
            control={<Checkbox {...field} checked={field.value} />}
            label={t("schoolClosureCoverage")}
          />
        )}
      />
      <Divider sx={{ my: "10px" }} />
    </>
  );
};

export default Waitlist;

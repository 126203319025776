import { useTranslation } from "react-i18next";
import _ from "lodash";

import { post, useFetch } from "utils/sdk";
import { BASE_URL } from "config/urls";

import { CompletedStatus, VoucherTypes } from "entities/FamilySpecialist/sdk";
import { IParent } from "entities/Parent/sdk";

export interface childData {
  firstName: string;
  lastName: string;
  age: number;
}
export type employmentStatus =
  | "EMPLOYED"
  | "TRAININGPROGRAM"
  | "IN_SCHOOL"
  | "OTHER";

const employmentStatusDisplayMap: { [key in employmentStatus]: string } = {
  EMPLOYED: "Employed",
  TRAININGPROGRAM: "Training Program",
  IN_SCHOOL: "In School",
  OTHER: "Other",
};

export const getEmploymentStatusDisplayValue = (
  status: employmentStatus
): string => {
  return employmentStatusDisplayMap[status];
};

export type gender = "MEN" | "WOMEN";

export const useGenderOptions = () => {
  const { t } = useTranslation();
  return [
    { label: t("male"), value: "MEN" },
    { label: t("female"), value: "WOMEN" },
  ];
};

export const useLanguageOptions = () => {
  const { t } = useTranslation();
  return [
    { label: t("english"), value: "english" },
    { label: t("spanish"), value: "spanish" },
    { label: t("capeverdean"), value: "capeverdean" },
    { label: t("haitian"), value: "haitian" },
  ] as const;
};

export const useRelationshipOptions = () => {
  const { t } = useTranslation();
  return [
    { label: t("standardChild"), value: "naturalChild" },
    { label: t("fosterChild"), value: "fosterChild" },
    { label: t("guardianChild"), value: "guardianChild" },
  ] as const;
};

export interface IPersonalInformationUpdate {
  gender: gender;
  preferred_language: string;
}

export interface IEmploymentStatusUpdate {
  employment_status: employmentStatus;
  employer_name?: string;
  other_status_description?: string;
}

export interface IAddressUpdate {
  street_address: string;
  unit_address: string;
  city: string;
  zip_code: string;
}

export interface IChildCreate {
  id?: number;
  first_name: string;
  last_name: string;
  gender: gender;
  preferred_language: string;
  date_of_birth: string | null;
  relationship: string;
  date_when_child_care_is_needed: string;
  child_has_a_disability: boolean;
  child_is_in_head_start_and_needs_more_hours_of_coverage: boolean;
}

export interface ISpecialCircumstances {
  family_has_experienced_domestic_violence: boolean;
  family_is_experiencing_homelessness: boolean;
  parent_has_a_disability: boolean;
  parent_is_a_member_of_the_us_military: boolean;
  parent_is_an_early_educator: boolean;
  parent_is_receiving_treatment_for_substance_abuse: boolean;
  teen_parent: boolean;
  child_only_needs_coverage_during_school_closures: boolean;
}

export interface IAdditionalInformation {
  additional_information: string;
}

export interface IHouseholdData {
  annual_income_per_year: number;
  annual_income_per_month: number;
}

interface IIncomeVerificationUpdate {
  household_size: number;
  total_annual_income: number;
  annual_income_status: string;
}

interface IParentWithID extends IParent {
  id: number;
}
export interface IParentsFamilyMember {
  family_members: IParentWithID[];
}

export const useReadableStatusFamilyMember = () => {
  const { t } = useTranslation();

  const keyToReadableStatus: { [key in CompletedStatus]: string } = {
    INCOMPLETE_SIGN_UP: t("incompleteSignUp"),
    INCOMPLETE_PROFILE: t("incompleteProfile"),
    READY_TO_ADD_TO_WAITLIST: t("readyToAddToWaitlist"),
    ON_WAITLIST: t("onWaitlist"),
    MISSING_REFERRAL_LETTER: t("missingReferralLetter"),
    ISSUED: t("onCCFA"),
    READY_TO_CREATE_VOUCHER: t("readyForCCFA"),
    INCOMPLETE_REFERRAL_INFORMATION: t("applicationInProcess"),
    READY_FOR_REVIEW: t("readyForReview"),
    INCOMPLETE_IE_PROFILE: "Incomplete Income Eligible Information",
  };

  const getReadableCompletedStatus = (key: CompletedStatus): string => {
    return _.get(keyToReadableStatus, key, key);
  };

  return { getReadableCompletedStatus };
};

export const personalInformationUpdate = (data: IPersonalInformationUpdate) =>
  post(`${BASE_URL}/api/family-members/personal-information/update/`, data);

export const employmentStatusUpdate = (data: IEmploymentStatusUpdate) =>
  post(`${BASE_URL}/api/family-members/employment-status/update/`, data);

export const addressUpdate = (data: IAddressUpdate) =>
  post(`${BASE_URL}/api/family-members/address/update/`, data);

export const useFamilyChildren = () => {
  return useFetch<IChildCreate[]>(`/api/family-members/family/children/`);
};

export const additionalInformationUpdate = (data: IAdditionalInformation) =>
  post(`${BASE_URL}/api/family-members/additional-information/update/`, data);

export const useHouseholdSize = (household_size: number | null) => {
  const url =
    household_size && household_size >= 2 && household_size <= 12
      ? `/api/family-members/family/household-size/${household_size}/`
      : undefined;

  return useFetch<IHouseholdData>(url);
};

export const incomeVerificationUpdate = (
  voucher_id: number,
  data: IIncomeVerificationUpdate
) =>
  post(
    `${BASE_URL}/api/family-members/family/authorization/${voucher_id}/on-boarding/household-size/update/ `,
    data
  );

export const completedStatusUpdate = (data: {
  completed_status: CompletedStatus;
}) => post(`${BASE_URL}/api/family-members/completed-status/update/`, data);

export const voucherStatusUpdate = (
  voucher_id: number,
  data: { type: VoucherTypes }
) =>
  post(
    `${BASE_URL}/api/family-members/authorization/${voucher_id}/voucher-type/update/`,
    data
  );

export const useParents = () => {
  return useFetch<IParentsFamilyMember>(`/api/family-members/parents/`);
};

export const specialCircumstanceUpdate = (
  voucher_id: number,
  data: ISpecialCircumstances
) =>
  post(
    `${BASE_URL}/api/family-members/family/authorization/${voucher_id}/booleans/update/ `,
    data
  );

export const getHelpAddNote = (family_id: number, data: { content: string }) =>
  post(
    `${BASE_URL}/api/family-members/family/${family_id}/get-help/note/create/ `,
    data
  );

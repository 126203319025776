import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Control, Controller, FieldErrors } from "react-hook-form";

import FullPageDialog from "components/FullPageDialog";
import Stack from "components/Stack";
import Divider from "components/Divider";
import Text from "components/Text";
import TextField from "components/TextField";

import { useMyFamily } from "entities/Family/sdk";

import useIncomeEligibleFlowNavigation from "../hooks";
import { URLS } from "config/urls";
import ParseFile from "components/ParseFile";
import FormControlLabel from "components/FormControlLabel";
import Checkbox from "components/Checkbox";

const RentalIncomeForm = ({
  control,
  errors,
}: {
  control: Control<any, any>;
  errors: FieldErrors<any>;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Controller
        name="total_rental_income"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            type="number"
            label={t("net_monthly_rental_income")}
            placeholder="$"
            InputLabelProps={{
              shrink:
                field.value !== "" &&
                field.value !== null &&
                field.value !== undefined,
            }}
            fullWidth
            margin="normal"
            error={!!errors.total_rental_income}
          />
        )}
      />
      <Controller
        name="is_resident_at_rental_property"
        control={control}
        render={({ field }) => (
          <FormControlLabel
            control={<Checkbox {...field} checked={field.value} />}
            label={t("is_resident_at_rental_property_label")}
          />
        )}
      />
    </>
  );
};

const RentalIncomePage = () => {
  const { t } = useTranslation();
  const { parentId: parentIdParam, voucherId: vouchersIdParam } = useParams<{
    parentId: string;
    voucherId: string;
  }>();
  const { data: family, mutate: reloadFamily } = useMyFamily();
  const parentId = parseInt(String(parentIdParam));
  const voucherId = parseInt(String(vouchersIdParam));

  const { getBackUrl } = useIncomeEligibleFlowNavigation();

  const parent = family?.family_members?.find(
    (parent) => parent.id === parentId
  );

  if (!parentId || !voucherId || !family || !parent) {
    return null;
  }

  return (
    <FullPageDialog
      title={t("rentalIncome")}
      backUrl={getBackUrl({ family })}
      closeUrl={URLS.HOME}
    >
      <ParseFile
        parentId={parentId}
        voucherId={voucherId}
        family={family}
        documentParsingTypes={["RENTAL_INCOME"]}
        getDefaultValues={(parentIncomeEligible) => ({
          total_rental_income: parentIncomeEligible?.total_rental_income,
          is_resident_at_rental_property:
            parentIncomeEligible?.is_resident_at_rental_property || false,
        })}
        fieldsMapping={{
          rental_income: "total_rental_income",
        }}
        FormComponent={RentalIncomeForm}
        reloadFamily={reloadFamily}
        sumFields={[
          {
            targetField: "total_rental_income",
            sourceField: "rental_income",
          },
        ]}
        verificationFilter={(document) => document.rental_income_verification}
        header={
          <>
            <Text>{t("rentalIncomeParagraphOne")}</Text>
            <Text>{t("rentalIncomeParagraphTwo")}</Text>

            <Stack spacing={1} direction="row" sx={{ alignItems: "center" }}>
              <Divider sx={{ flex: 1 }} />
              <Text variant="titleMedium" sx={{ fontWeight: 600 }}>
                {t("uploadFor")} {parent.first_name} {parent.last_name}
              </Text>
              <Divider sx={{ flex: 1 }} />
            </Stack>
          </>
        }
      />
    </FullPageDialog>
  );
};

export default RentalIncomePage;

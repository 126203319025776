import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import FullPageDialog from "components/FullPageDialog";

import { useMyFamily } from "entities/Family/sdk";

import { URLS } from "config/urls";
import { ISpecialCircumstances } from "entities/FamilyMember/sdk";
import { reverse } from "utils/urls";
import { authorizationSpecialCircumstancesUpdate } from "entities/Authorization/sdk";
import SpecialCircumstancesForm from "entities/Authorization/components/SpecialCircumstancesForm";

const SpecialCircumstancePage = () => {
  const { data: family } = useMyFamily();
  const { voucherId: voucherIdParam } = useParams<{ voucherId: string }>();
  const voucher = family?.vouchers.find(
    (voucher) => voucher.id === parseInt(voucherIdParam ?? "")
  );
  const { t } = useTranslation();
  const navigate = useNavigate();

  if (!voucher || !voucherIdParam) {
    return null;
  }

  const handleSave = (data: ISpecialCircumstances) => {
    return authorizationSpecialCircumstancesUpdate({
      voucher_id: voucherIdParam,
      data: data,
    }).then(() => {
      navigate(
        reverse(URLS.MISSING_INFORMATION_WAITLIST_SUCCESS, {
          voucherId: voucherIdParam,
        })
      );
    });
  };

  return (
    <FullPageDialog
      title={t("specialCircumstance")}
      progressBarPercentage={70}
      backUrl={reverse(URLS.MISSING_INFORMATION_INCOME_VERIFICATION, {
        voucherId: voucherIdParam,
      })}
      closeUrl={URLS.HOME}
    >
      <SpecialCircumstancesForm voucher={voucher} onSave={handleSave} />
    </FullPageDialog>
  );
};

export default SpecialCircumstancePage;

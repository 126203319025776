import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import Divider from "components/Divider";
import IconButton from "components/IconButton";
import Text from "components/Text";
import Stack from "components/Stack";
import BasicButton from "components/BasicButton";
import Link from "components/Link";
import Chip from "components/Chip";

import { IDocument, useFamilyDetail } from "entities/FamilySpecialist/sdk";
import {
  ArrowRightIcon,
  CheckIcon,
  DocumentsIcon,
  ImageIcon,
  PlusIcon,
} from "icons";
import { colors } from "theme";

import FamilySpecialistDocumentUpload from "../FamilySpecialistDocumentUpload";
import {
  createFamilyFilesZip,
  documentBooleansToLabels,
  refetchFamilyFilesZip,
} from "entities/Documents/sdk";
import { downloadFileWithCustomName } from "utils/download";

interface IProps {
  familyId: number;
}

const getDocumentLabels = (document: IDocument): string[] => {
  const labels: string[] = [];
  for (const [key, value] of Object.entries(documentBooleansToLabels)) {
    if (document[key as keyof IDocument]) labels.push(value);
  }

  return labels;
};

const FamilySpecialistDocumentsListCard = ({ familyId }: IProps) => {
  const { t } = useTranslation();
  const { data: familyData, mutate: refetchFamilyDetail } =
    useFamilyDetail(familyId);

  const [isUploadModalOpen, setUploadModalOpen] = useState(false);

  const [exportInProgress, setExportInProgress] = useState(false);
  const [createdZipResourceId, setCreatedZipResourceId] = useState<
    number | null
  >(null);

  const handleUploadDocumentsOpen = () => {
    setUploadModalOpen(true);
  };

  const handleUploadDocumentsClose = () => {
    setUploadModalOpen(false);
    refetchFamilyDetail();
  };

  const handleExportClick = () => {
    setExportInProgress(true);
    createFamilyFilesZip(familyId)
      .then((res: any) => {
        if (res?.id) {
          setCreatedZipResourceId(res.id);
        } else {
          setExportInProgress(false);
        }
      })
      .catch(() => {
        setExportInProgress(false);
      });
  };

  if (!familyData) {
    return null;
  }

  useEffect(() => {
    if (exportInProgress && createdZipResourceId) {
      const intervalId = setInterval(
        () =>
          refetchFamilyFilesZip(familyId, createdZipResourceId).then((data) => {
            if (data?.is_ready) {
              clearInterval(intervalId);
              refetchFamilyFilesZip(familyId, createdZipResourceId);
              if (data?.link) {
                downloadFileWithCustomName(
                  data.link,
                  `${familyData.creator.first_name || "Unknown"}_${familyData.creator.last_name || "Unknown"}_documents_archive.zip`
                );
              } else toast.error("Failed to export family documents");
              setExportInProgress(false);
              setCreatedZipResourceId(null);
            }
          }),
        2000
      );
    }
  }, [exportInProgress, createdZipResourceId, familyId]);

  return (
    <Stack
      spacing={2}
      sx={{
        p: "15px",
        borderRadius: "16px",
        backgroundColor: colors.surface,
      }}
    >
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Text variant="titleMedium">{t("documents")}</Text>
        <IconButton onClick={handleUploadDocumentsOpen}>
          <PlusIcon />
        </IconButton>
      </Stack>
      <Divider />
      {familyData.documents.map((document) => (
        <>
          <Stack
            key={document.id}
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Stack direction={"row"} alignItems={"center"} gap="15px">
              {document.file_type === "application/pdf" ? (
                <DocumentsIcon />
              ) : (
                <ImageIcon />
              )}
              <Text
                variant="labelLarge"
                fontWeight="500"
                sx={{ wordBreak: "break-word" }}
              >
                {document.original_file_name}
              </Text>
            </Stack>
            <Link to={document.file} external target="_blank">
              <IconButton>
                <ArrowRightIcon />
              </IconButton>
            </Link>
          </Stack>
          <Stack direction={"row"} gap="5px" flexWrap={"wrap"}>
            {getDocumentLabels(document).map((label) => (
              <Chip icon={<CheckIcon />} label={<Text>{label}</Text>} />
            ))}
          </Stack>
          <Divider />
        </>
      ))}

      <BasicButton
        label={"Export Family Documents"}
        onClick={handleExportClick}
        isLoading={exportInProgress}
      />

      <FamilySpecialistDocumentUpload
        open={isUploadModalOpen}
        onClose={handleUploadDocumentsClose}
        familyId={familyId}
      />
    </Stack>
  );
};

export default FamilySpecialistDocumentsListCard;

import { forwardRef, useImperativeHandle, useState } from "react";
import { toast } from "react-toastify";

import dayjs from "dayjs";

import _ from "lodash";

import { useTranslation } from "react-i18next";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import StyledSelect from "components/StyledSelect";
import POWERFULSelect from "components/POWERFULSelect";
import FileUploadBox from "components/FileUploadBox";
import FormHelperText from "components/FormHelperText";
import Stack from "components/Stack";
import CircularProgress from "components/CircularProgress";
import Text from "components/Text";

import {
  parseAndVerifyPlacementDocument,
  refetchNewsletterIsReady,
} from "../sdk";
import theme from "theme";

interface IProps {
  onSave: () => void;
  vouchers: {
    id: number;
    type: string;
    created_at: string;
    children: {
      id: number;
      first_name: string;
      last_name: string;
    }[];
  }[];
}

interface IPlacementFormValues {
  children: string[];
  voucher: number;
  file: File;
}

export interface UploadPlacementLetterFormRef {
  submitForm: () => void;
}

const UploadPlacementLetterForm = forwardRef<
  UploadPlacementLetterFormRef,
  IProps
>(({ onSave, vouchers }, ref) => {
  const { t } = useTranslation();
  const { control, handleSubmit, watch, formState } =
    useForm<IPlacementFormValues>();
  const [file, setFile] = useState<File | null>(null);
  const [fileError, setFileError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const voucher_id = watch("voucher");

  const childOptions =
    vouchers
      .find((voucher) => voucher.id === voucher_id)
      ?.children?.map((child) => {
        let label = `${child.first_name} ${child.last_name}`;
        if (!child.first_name && !child.last_name) {
          label = `Unknown name (ID ${child.id})`;
        }
        return {
          label,
          value: child.id?.toString() as string,
        };
      }) || [];

  const voucherOptions = vouchers
    .filter((voucher) =>
      [
        "DTA_REFERRAL",
        "DCF_REFERRAL",
        "HOMELESS_REFERRAL",
        "INCOME_ELIGIBLE",
      ].includes(voucher.type)
    )
    .map((voucher) => ({
      value: voucher.id,
      label: `${voucher.type} - ${dayjs(voucher.created_at).format("MM/DD/YYYY")}`,
    }));

  const onSubmit: SubmitHandler<IPlacementFormValues> = (data) => {
    if (!file) {
      setFileError("Please upload a file before submitting!");
      return;
    }

    setIsSubmitting(true);
    parseAndVerifyPlacementDocument({
      file,
      voucher: data.voucher,
      children: data.children || [],
    })
      .then((response: any) => {
        const intervalId = setInterval(
          () =>
            refetchNewsletterIsReady(response.id).then((isReady) => {
              if (isReady?.is_ready) {
                clearInterval(intervalId);
                refetchNewsletterIsReady(response.id);
                toast.success("Newsletter parsed successfully!");
                setIsSubmitting(false);
                onSave();
              }
            }),
          2000
        );
      })
      .catch((error: any) => {
        setIsSubmitting(false);
        toast.error(error.detail || "Error occurred while uploading.");
      });
  };

  useImperativeHandle(ref, () => ({
    submitForm: handleSubmit(onSubmit),
  }));

  return (
    <>
      <Controller
        name="voucher"
        control={control}
        rules={{ required: t("fieldRequired") }}
        disabled={voucherOptions.length === 0}
        render={({ field }) => (
          <StyledSelect
            {...field}
            label={t("authorization")}
            options={voucherOptions}
            error={!!formState.errors.voucher || voucherOptions.length === 0}
            helperText={
              formState.errors.voucher?.message ||
              (voucherOptions.length === 0 &&
                "No Referral Authorizations found!")
            }
            fullWidth
            margin="normal"
          />
        )}
      />
      <Controller
        name="children"
        control={control}
        render={({ field }) => (
          <POWERFULSelect
            multiple
            value={field.value || []}
            onChange={field.onChange}
            label={t("children")}
            options={childOptions}
          />
        )}
      />
      <Stack sx={{ mt: theme.spacing(2) }}>
        <FileUploadBox file={file} setFile={setFile} disabled={isSubmitting} />
        {fileError && (
          <FormHelperText error sx={{ mt: 2 }}>
            {fileError}
          </FormHelperText>
        )}
      </Stack>
      {isSubmitting && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <CircularProgress thickness={8} size={48} sx={{ color: "#DA2C52" }} />
          <Text variant="labelLarge" sx={{ marginTop: "16px" }}>
            {t("verifyingDocuments")}
          </Text>
        </div>
      )}
    </>
  );
});

export default UploadPlacementLetterForm;

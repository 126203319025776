import { useTranslation } from "react-i18next";
import { useRef, useState } from "react";

import AddButton from "components/AddButton";
import ParentStatusItem from "components/ParentStatusItem";
import Stack from "components/Stack";

import AddParentForm, { AddParentFormRef } from "../AddParentForm";

import { IFamilyMember } from "entities/FamilySpecialist/sdk";
import { getEmploymentStatusDisplayValue } from "entities/FamilyMember/sdk";
import EditPanel from "..";
import { useData } from "../DataContext";

interface IProps {
  refetchFamilyDetail: () => void;
}

const ParentStatus = ({ refetchFamilyDetail }: IProps) => {
  const { t } = useTranslation();
  const [isAdding, setIsAdding] = useState(false);
  const [selectedParent, setSelectedParent] = useState<IFamilyMember>();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const familyData = useData();
  const addParentFormRef = useRef<AddParentFormRef>(null);

  const handleAddParentClick = () => {
    setSelectedParent(undefined);
    setIsAdding(true);
  };

  const handleSaveParent = () => {
    setIsAdding(false);
  };

  const handleEditParent = (parent: IFamilyMember) => {
    setSelectedParent(parent);
    setIsAdding(true);
  };

  const handleSaveClick = () => {
    if (addParentFormRef.current) {
      addParentFormRef.current.submitForm();
    }
  };

  if (familyData === null) {
    return null;
  }

  return (
    <Stack spacing={2}>
      <EditPanel
        open={isAdding}
        onClose={() => setIsAdding(false)}
        title={t(selectedParent ? "Edit Parent" : "Add Parent")}
        onSaveClick={handleSaveClick}
        isSubmitDisabled={isSubmitDisabled}
      >
        <AddParentForm
          onSave={handleSaveParent}
          initialData={selectedParent}
          ref={addParentFormRef}
          familyId={familyData.id}
          refetchFamilyDetail={refetchFamilyDetail}
          setIsSubmitDisabled={setIsSubmitDisabled}
        />
      </EditPanel>
      <>
        {familyData.family_members.map((parent) => (
          <ParentStatusItem
            key={parent.id}
            name={
              !parent.first_name && !parent.last_name
                ? "Unknown name"
                : `${parent.first_name} ${parent.last_name}`
            }
            employmentStatus={getEmploymentStatusDisplayValue(
              parent.employment_status
            )}
            employerName={parent.employer_name}
            otherDescription={parent.other_status_description}
            onEdit={() => handleEditParent(parent)}
          />
        ))}
        <Stack alignItems="center" style={{ marginBottom: "20px" }}>
          <AddButton label={t("addParent")} onClick={handleAddParentClick} />
        </Stack>
      </>
    </Stack>
  );
};

export default ParentStatus;

import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import FullPageDialog from "components/FullPageDialog";
import Container from "components/Container";
import Stack from "components/Stack";

import { ISpecialCircumstances } from "entities/FamilyMember/sdk";
import { familySpecialistSpecialCircumstanceUpdate } from "entities/FamilyEdit/sdk";
import SpecialCircumstanceForm from "entities/Family/components/SpecialCircumstanceForm";
import { useFamilyDetail } from "entities/FamilySpecialist/sdk";

import { reverse } from "utils/urls";
import { URLS } from "config/urls";

const FamilySpecialistSpecialCircumstances = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { familyId } = useParams<{ familyId: string }>();
  const familyIdNumber = familyId ? parseInt(familyId) : undefined;
  if (!familyIdNumber) return null;

  const { data: familyData } = useFamilyDetail(familyIdNumber);

  if (!familyData) return null;

  const handleSave = (data: ISpecialCircumstances) => {
    return familySpecialistSpecialCircumstanceUpdate(
      familyIdNumber,
      familyData?.vouchers[0].id,
      data
    ).then(() => {
      navigate(
        reverse(URLS.FAMILY_SPECIALIST_ADDITIONAL_INFORMATION, {
          familyId: familyIdNumber,
        })
      );
    });
  };

  return (
    <FullPageDialog
      title={t("specialCircumstance")}
      backUrl={reverse(URLS.FAMILY_SPECIALIST_CHILDREN, {
        familyId: familyIdNumber,
      })}
    >
      <Container
        maxWidth="lg"
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Stack
          sx={{
            flex: 1,
            height: "100%",
            padding: 2,
          }}
        >
          <SpecialCircumstanceForm
            onSave={handleSave}
            initialData={familyData}
          />
        </Stack>
      </Container>
    </FullPageDialog>
  );
};

export default FamilySpecialistSpecialCircumstances;
